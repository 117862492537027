import { Box, Menu, MenuItem, Tab, Tabs,Button , Typography, Modal } from "@mui/material";
import logo from "../assets/images/new-logo.svg";
import crossborder from "../assets/images/crossbordericon.svg";
import domestic from "../assets/images/domestictransporticon.svg";
import { lazy, Suspense, useEffect, useState } from "react";
import Notification from "../assets/images/notifications.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { aguserStore, bookingsStore, breadCrumbStore, configStore, contractsStore, filtersState, loginStore, newDashboardState, viewBookingStore } from "../state/CommonState";
import ArrowDown from "../assets/images/arrowdown.svg"
// import {Dashboard} from "../pages/Dashboard.js";
import { ArrowDownward, ArrowDownwardRounded } from "@mui/icons-material";
import profile from "../assets/images/profile.svg";
import access from  "../assets/images/lock-access.svg";
import location from "../assets/images/location.svg";
import tracking from "../assets/images/Tracking.svg";
import settings from "../assets/images/settings.svg";
import help from "../assets/images/help.svg";
import signout from "../assets/images/sign-out.svg";
import AguserApi from "../state/AguserApi";
import HomeMain from "../enquiry/home-main";
import ListBookingsMainv2 from "../mybooksv2/booking-list-main-v2";

import { taskStore } from "../mytasks/task-store";
import { enquiryStore } from "../enquiry/enquiry-data";
import { subscribe, useSnapshot } from "valtio";
import DtBookingMain from "../dt/DtBookingMain";
import DtContractMain from "../dt/DtcontractMain";
import ListEnquiry from "../enquiry/list-enquiry";
import PricingScreen2 from "../pricing/pricing.screen.v2";
import MyProfileNew from "../agusers/my-profile-new.js";
import Devices from "../components/devices.js";
import { WelcomeCard } from "./welcomecard.js";
import CustomerMyactions from "../my-actions/customer-myactions.js";
import { CustomTabBar } from "./CustomTabBar.js";
import AcceptRegistration from "../agusers/accept-registration.js";
import TrackMyBooking from "../trackmybookings/TrackMyBookings.js";
import Spotv3 from "../spotv3/spot.v3";
import Api from "../state/Api.js";
import HomeMainv2 from "../enquiryv2/home-main-v2.js";
import BookingsApi from "../state/bookings-api.js";
import Taskapi from "../mytasks/tasks-api.js";
import { ProfilePage, SettingsPage } from "../pages/SettingPage.js";

import { DocumentViewer } from "../pages/DocumentViewer.js";
import TransactionHistory from "../assets/images/TransactionHistory.svg";
import TrackMyBookingV2 from "../trackmybookings/TrackMyBooking-v2.js";
import ExcelTable from "./ExcelTable.js";
import { useGlobalState } from "../GlobalState.js";
import  EntityCreWarnPopup  from "./EntityCreWarnPopup.js";
import HomeMainv3 from "../enquiryv3/home-main-v3.js";
// import { HomeMainNewV3, QuotationAndContract } from "../pages/HomeMainNewV3.js";
import { Constants } from "../util/Utils.js";
// import {DashboardV2} from "../pages/DashboardV2.js"
import ApiAgTable from "../components/ag-table/api_ag-table.js";
import { truckLoader } from "./TruckLoader.js";
const BookingWithVisibility =  lazy(()=>import("../pages/BookingWithVisibility.js").then(module=>({default:module.BookingWithVisibility})));
const TaskList = lazy(()=>import("../mytasks/task-list"));
const EnquiriesPage = lazy(()=>import( "../pages/EnquiresPage.js").then(module=>({default:module.EnquiriesPage})));
const HomeMainNewV3 = lazy(()=>import( "../pages/HomeMainNewV3.js").then(module=>({default:module.HomeMainNewV3})));
const Dashboard = lazy(()=>import("../pages/Dashboard.js").then(module=>({default:module.Dashboard})));
const DashboardV2 = lazy(()=>import("../pages/DashboardV2.js").then(module=>({default:module.DashboardV2})));
// import TrackMyBookingV2 from "../trackmybookings/TrackMyBooking-v2.js";

function InitialitingDefaultStateForHome(){
    breadCrumbStore.setBreadCrumbs(["Create Booking"],"Create Booking");
            newDashboardState.isProfileSelected = false
            filtersState.initialize();
            bookingsStore.initialize();
            enquiryStore.initialize();
            contractsStore.current = JSON.parse(JSON.stringify({}))
bookingsStore.current.schedule = {};
}

const CrossBorederTabsDetails = [
    { title: "Home",component :<Suspense fallback={<p>Loading...</p>}><Dashboard /></Suspense>, onClickHandler : ()=>{
            // breadCrumbStore.reset();
           InitialitingDefaultStateForHome();
            
    } },
    { title: "Dashboard",component :<Suspense fallback={<p>Loading...</p>}>
        <DashboardV2/>
    </Suspense>, onClickHandler : ()=>{
        breadCrumbStore.reset(); 
        newDashboardState.isProfileSelected = false
} },
    /*{title : "Quick Quote v2", component : <PricingScreen2 />, onClickHandler:()=>{
        configStore.homescreenCurrentShipmentType = "LCL"
        enquiryStore.initialize();
    }},*/
//     { title : "Quick Quote", component : <Spotv3 /> , 
//     // onClickHandler : ()=> {
//     //     console.log(configStore.currentSelectedEntity);
      
//     // } 
// },
    { title: "Enquiries", component :<Suspense fallback={<p>Loading...</p>}>
    <EnquiriesPage/>
</Suspense>, onClickHandler : ()=>{
            // configStore.showQoute = false;
            // enquiryStore.initialize();
            // configStore.homescreenCurrentView = "list";
            newDashboardState.isProfileSelected = false
    }  },
    { title: "Create bookings", component :<Suspense fallback={<p>Loading...</p>}> <HomeMainNewV3/></Suspense>
        //  <HomeMainv3 /> 
         , onClickHandler:()=>{
            newDashboardState.isProfileSelected = false
            // console.log("t]edhu execute aagudha  . . . . . ")
            // enquiryStore.initialize();
           
            contractsStore.current = JSON.parse(JSON.stringify({}))
bookingsStore.current.schedule = {};
            breadCrumbStore.setBreadCrumbs([Constants.createbookings],Constants.createbookings);
        if(configStore.homescreenCurrentView==="new"){
                configStore.homescreenCurrentView = "list"
            }
            // configStore.homescreenCurrentView = "list";
            // configStore.displaySchedules = false; 
    } },
    // { title: "Bookings" , component :  <ListBookingsMainv2 />},
    // { title : "Visibility", component : <CustomerMyactions  />, },
    { title: "Pending Items", component : <Suspense fallback={<p>Loading...</p>}><TaskList/></Suspense>, onClickHandler : ()=>{
        taskStore.isViewAll = true;
        newDashboardState.isProfileSelected = false
    } },
    { title : "My Bookings", component : <Suspense fallback={<p>Loading...</p>}><BookingWithVisibility/></Suspense>, onClickHandler:()=>{
        breadCrumbStore.setBreadCrumbs(["My Bookings"])
        breadCrumbStore.onBackArrow = undefined;
        newDashboardState.isProfileSelected = false
    } },
    // { title: 'Track My Booking', component: <TrackMyBookingV2 />}
];

// let TrackMyBookingDetails = [{
//     title: 'Ocean', component: <TrackMyBookingV2 />
// }, {
//     title: 'Air', component: <TrackMyBookingV2 />
// }]

const DomesticTabDetails = [
    { title: "Dashboard",component :<Dashboard />, onClickHandler : ()=>{
            breadCrumbStore.setCurrentCrumb(0);
    } },
    {title : "Contracts", component :  <DtContractMain/>, onClickHandler : ()=>{
            configStore.dtCurrentView = "list";
    }},
    {title : "Bookings", component : <DtBookingMain/>, onClickHandler : ()=>{
            configStore.dtCurrentView = "list";
    }}
]

// function TabContainer({ selected, title, onClick }) {
//     return <Box onClick={onClick} sx={selected ? {
//         borderBottom: "2px solid", borderBottomColor: "#2B6ED4", color: "#2B6ED4", height: "34px", "&:hover": {
//             cursor: "pointer"
//         }
//     } : {
//         color: "#555555", height: "34px", "&:hover": {
//             cursor: "pointer"
//         }
//     }}>
//         <Typography sx={{ fontFamily: "Figtree", fontSize: "14px", fontWeight: "400", padding: '0px 10px' }}>{title}</Typography>
//     </Box>
// }



function CustomMenu({title,items,onClickHandler,currentValue}){
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const [value,setTitle] = useState(currentValue || items[0]);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        // breadCrumbStore.reset();
        enquiryStore.current.shipmentType='LCL'
    },[value ])
    useEffect(()=>{
        setTitle(currentValue)
    },[currentValue])


    return <Box sx={{height :"100%", display:"flex",flexDirection:"row",marginX:"8px"}}>
        <Typography sx={{fontFamily:"Figtree",color:"#F4B14A",fontWeight:"bolder",fontSize:'14px'}}>{title}:</Typography>
        <Box onClick={handleClick}   sx={{display:"flex",flexDirection:"row",marginLeft:"5px",alignItems:"center","&:hover":{
            cursor:"pointer"
        }}}>
            <Typography
            sx={{color:"white",
            maxWidth:"200px",
            overflow:"hidden",
                    display: "-webkit-box",
        whiteSpace:"normal",
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: "1",
   overflow:"hidden",
                    // textOverflow:"ellipsis",
    fontSize:"14px"
                }}>{value}</Typography>
            <img style={{marginLeft:"5px",filter: "brightness(0) invert(1)"}} src={ArrowDown}/>
        </Box>
        <Menu
              sx={{height:"60%"}}
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
              {items?.map((item,i)=><MenuItem sx={{
                textAlign:"start",
                wordBreak:"break-word",
                whiteSpace:"normal",
                maxWidth:"300px",margin:"8px", borderRadius:"4px",
               color:"#555555",
              "&:hover":{
                 backgroundColor:"#2C358A",
                 color:"white"
              }}} onClick={()=>{

                if(onClickHandler){
                    onClickHandler(i);
                }
                setTitle(item);
                setAnchorEl(null);
            }} >{item}</MenuItem>)}
        </Menu>
    </Box>
}


const profileTabs = [
    //     {
    //     action : "Profile",
    //     icon : profile,
    //     handler : ()=>{
    //         newDashboardState.isProfileSelected = true;
    //         configStore.currentMainMenu = <MyProfileNew/>
    //     }
    // },{
    //     action : "Access Control",
    //     icon : access,
    //     handler : ()=>{
    //         newDashboardState.isProfileSelected = true;
    //         configStore.currentMainMenu =  <Devices />
    //     }
    // },
    {
    action : "Settings",
    icon : settings,
    handler : ()=>{
            newDashboardState.isProfileSelected = true;
        breadCrumbStore.setBreadCrumbs(["Settings"],"Settings");
        }
    },
    {
    action : "Help Center",
    icon : help,
    handler : ()=>{
            newDashboardState.isProfileSelected = true;
        breadCrumbStore.setBreadCrumbs(["Help Center"],"Help Center");
        }
    },
    {
    action : "Transaction History",
    icon : TransactionHistory,
    handler : ()=>{
            newDashboardState.isProfileSelected = true;
        breadCrumbStore.setBreadCrumbs(["Transaction Data"],"Transaction Data");
        }
    },
    // {
    //     action : "Settings",
    //     icon : settings,
    //     handler : ()=>{
    //         newDashboardState.isProfileSelected = true; 
    //         breadCrumbStore.setBreadCrumbs(["Settings"],"Settings");
    //     }
    // },
    // {
    //     action : "Help Center",
    //     icon : help,
    //     handler : ()=>{
    //         newDashboardState.isProfileSelected = true; 
    //         breadCrumbStore.setBreadCrumbs(["Help Center"],"Help Center");
    //     }
    // },
    {
    action : "Sign Out",
    icon : signout,
    handler : ()=>{
            let token = localStorage.getItem("devicetoken")
            let d = JSON.parse(localStorage.getItem("ag_userrec"))

        if(!d){
                loginStore.reinitialize()
                localStorage.removeItem('ag_email')
                localStorage.removeItem('tour')
                localStorage.removeItem('ag_current_entity')
                localStorage.removeItem('devicetoken')
                let isRememberedUser = localStorage.getItem("ag_remember_user");
            if(isRememberedUser){
                    localStorage.removeItem('ag_userrec')
            }else{
                    sessionStorage.removeItem('ag_userrec')
                }
                localStorage.removeItem("ag_remember_user")
        }else{
            AguserApi.logout({token:token,email:d.aguserRec.email , selectgst : configStore.currentSelectedBranch.gst },()=>{
                    console.log("logout")
                    window.location.reload()
                })
            }

            newDashboardState.isProfileSelected = false;
        }
    }
]

function ProfileAvatar({ content, commonHandler }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const items = profileTabs;
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    useEffect(()=>{
        if (configStore?.currentSelectedEntity?.isTrackMyBooking?.toLowerCase() === "yes") {
            if (!CrossBorederTabsDetails.some(user => user.title === 'Track My Booking')) {
                CrossBorederTabsDetails.push({ title: 'Track My Booking', component: <TrackMyBookingV2 /> });
            }
        } else {
            const index = CrossBorederTabsDetails.findIndex(e => e.title === 'Track My Booking');
            if (index !== -1) {
                CrossBorederTabsDetails.splice(index, 1);
            }
            // newDashboardState.selectedTab = 0;
        }},[configStore.currentSelectedEntity])

    return <Box  onClick={handleClick}  sx={{display:"flex", flexDirection:"row", "&:hover":{
        cursor : "pointer"
    }}}>
    <Box sx={{  height: "30px", width: "30px", borderRadius: "50%", backgroundColor: "#F4B14A", display: "flex", justifyContent: "center", alignItems: "center", color: "white", textTransform:"uppercase",fontSize:"12px" }}>{content}</Box>
    <img style={{marginLeft:"5px",filter: "brightness(0) invert(1)"}} src={ArrowDown}/>
        <Menu
              sx={{height:"60%"}}
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
              {items.map((item)=><MenuItem

                sx={{
                textAlign:"start",
                wordBreak:"break-word",
                whiteSpace:"normal",
                width:"180px",margin:"8px", borderRadius:"4px",
               color:"#555555",
               "& img" :{
                filter : "brightness(0) saturate(100%) invert(31%) sepia(4%) saturate(22%) hue-rotate(49deg) brightness(98%) contrast(85%)"
                    },
              "&:hover":{
                 backgroundColor:"#2C358A",
                 color:"white",
                        '& img': {
                            filter: 'brightness(0) invert(1)',
                        },
              }}} onClick={(e)=>{
                if(item?.handler){
                        item.handler();
                    }
                if(commonHandler){
                        commonHandler();
                    }
                    e.stopPropagation();
                    setAnchorEl(null);
              }} ><img  src={item.icon}/> <span style={{paddingLeft:"8px"}}>{item.action}</span></MenuItem>)}
        </Menu>
    </Box>
}

function ProfileSelection(){
    return <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", backgroundColor: "transparent", justifyContent: "start", gap:1 }}>
                    <WelcomeCard/>
        {configStore.currentMainMenu}
    </Box>
}

function NotificatioIcon() {
    return <Box sx={{ height: "34px", position: "relative",display:"flex",justifyContent:"center", alignItems:"center" }}>
        <Box sx={{ height: "5px", width: "5px", borderRadius: "50%", position: "absolute", right: "5px", top: "2px", backgroundColor: "#2C358A" }}></Box>
        <Box sx={{
            height: "30px", width: "30px", borderRadius: "50%", backgroundColor: "#EFEFEF", display: "flex", justifyContent: "center", alignItems
                : "center"
        }}>
            <img height="14px" width="14px" src={Notification} />
        </Box>

    </Box>
}


let TrackMyBookingDetails = [];

export function AgNavBar({selectedTabIndex,setTabIndex}) {
    
    const [selectedTransport, setTransport] = useState(0);
    let { changeCurrentMenu } = useGlobalState()
    const [branchList, setbranchList] = useState([]);
    const [entityCrePopUpWarn, setentityCrePopUpWarn] = useState(false);
    const [selectedBranch, setselectedBranch] = useState("ALL");
    const isActive  = loginStore.userRec.aguserRec.status === 'ACTIVE';
    const navigate = useNavigate();
    const location = useLocation().pathname;
    useSnapshot(loginStore)
    useSnapshot(configStore)
    
    console.log('koko22', configStore.currentSelectedEntity);
    if (TrackMyBookingDetails.length === 0) {
       
        if (configStore.currentSelectedEntity?.isOceanChecked) {
    
            TrackMyBookingDetails.push({ title: 'Ocean', component: <TrackMyBookingV2 /> });
        }

        if (configStore.currentSelectedEntity?.isAirChecked) {
           
            TrackMyBookingDetails.push({ title: 'Air', component: <TrackMyBookingV2 /> });
        }
    }

    //  useSnapshot(configStore.currentSelectedBranch)
    aguserStore.aguserRec = JSON.parse(localStorage.getItem('ag_userrec'))?.aguserRec
   useEffect(()=> {
    console.log('kuji22', configStore.currentSelectedEntity , loginStore.userRec?.companyList);
    if(!configStore.currentSelectedEntity.entityId && loginStore.userRec?.companyList?.length > 0){
            configStore.currentSelectedEntity = loginStore.userRec?.companyList[0]
        }
        setbranchList([])
        console.log('kuji', configStore.currentSelectedEntity?.entityId);
    const selectedentity = loginStore?.userRec?.companyList?.filter((r,i)=>r.entityId === configStore.currentSelectedEntity?.entityId)
    if(selectedentity){
        let branchess = selectedentity[0]?.branches
    console.log("Current: ", configStore.currentSelectedEntity , configStore.currentSelectedBranch);
    let branches = JSON.stringify(selectedentity[0]?.branches )
    if(branchess?.length > 1 || branchess?.length === 0){
            branches = JSON.parse(branches)
        branches.unshift({"branchId" : "ALL"})
            setbranchList(branches)
            setselectedBranch(branches[0]?.branchId)
        configStore.currentSelectedBranch= branches[0]
    }else if (branchess?.length === 1) {
            branches = JSON.parse(branches)
            setbranchList(branches)
            setselectedBranch(branches[0]?.branchId)
        configStore.currentSelectedBranch= branches[0]
        }
    }
    }, [])

    useEffect(() => {
        // configStore.currentSelectedEntity?.entityName
        setbranchList([])
        const selectedentity = loginStore?.userRec?.companyList?.filter((r,i)=>r.entityId === configStore.currentSelectedEntity?.entityId)
        if(selectedentity){
        let branchess = selectedentity[0]?.branches
        console.log(configStore.currentSelectedEntity , configStore.currentSelectedBranch);
        let branches = JSON.stringify(selectedentity[0]?.branches )
        if(branchess?.length > 1 || branchess?.length === 0){
            branches = JSON.parse(branches)
            branches.unshift({"branchId" : "ALL"})
            setbranchList(branches)
            setselectedBranch(branches[0]?.branchId)
        }else if (branchess?.length === 1) {
            branches = JSON.parse(branches)
            setbranchList(branches)
            setselectedBranch(branches[0]?.branchId)
        }
    }
        // configStore.currentSelectedBranch.branchId = "ALL"
        // setselectedBranch('All')
    },[configStore.currentSelectedEntity])

   

    // useEffect(()=>{
    //     newDashboardState.selectedTransportMode = selectedTransport;
    //     breadCrumbStore.setCurrentCrumb(0);
    //     newDashboardState.isProfileSelected = false;
    // },[selectedTransport])

    // useEffect(()=>{
    //     if(selectedTransport===0){
    //         if(CrossBorederTabsDetails[selectedTabIndex]?.onClickHandler){
    //             CrossBorederTabsDetails[selectedTabIndex].onClickHandler();
    //         }
    //     }else if(selectedTransport===1){
    //         if(DomesticTabDetails[selectedTabIndex]?.onClickHandler){
    //             DomesticTabDetails[selectedTabIndex].onClickHandler();
    //         }
    //     }
    // },[selectedTabIndex])

    // useEffect(() => {
    //     console.log("The location i s ", location.pathname.split("/"));
    //     if (location?.pathname) {
    //         let path = location.pathname.split("/");
    //         if (path[1]) {
    //             let indexAt = CrossBorederTabsDetails.findIndex(({ title }) => title.toLowerCase() === path[1]);
    //             if (indexAt === -1) {
    //                 setTabIndex(0);
    //             } else {
    //                 setTabIndex(indexAt);
    //             }
    //         }else{
    //             setTabIndex(0);
    //         }
    //     }
    // }, [location])

    const handleChange = (event, newValue) => {
        if(selectedTransport===0){
            if(CrossBorederTabsDetails[newValue]?.onClickHandler){
                CrossBorederTabsDetails[newValue].onClickHandler();
            }
        }else if(selectedTransport===1){
            if(DomesticTabDetails[newValue]?.onClickHandler){
                DomesticTabDetails[newValue].onClickHandler();
            }
        }
        newDashboardState.isProfileSelected = false;
        setTabIndex(newValue);
        // navigate(`/${TabsDetails[newValue].title.toLowerCase()}`);
    };
    let firstName =  aguserStore.aguserRec?.firstName?.length > 0 ? aguserStore.aguserRec?.firstName[0] : ""
    let lastName =  aguserStore.aguserRec?.lastName?.length > 0 ? aguserStore.aguserRec?.lastName[0] : ""

    return <>
        <Box sx={{ height:"50%", display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center", padding: "8px 12px 5px 12px"}}>

            <span style={{ display: "inline-flex" , height :"100%"}}>
                <img src={logo} />
                <Box sx={{ width: "10px" }}></Box>
                {/* { isActive && <><CustomTabBar onClick={() => {
                    setTransport(0);
                }} selected={selectedTransport === 0} title="Cross-Border" icon={crossborder} />
                    <Box sx={{ width: "10px" }}></Box>
                    {configStore.currentSelectedEntity?.entityId &&
                     <>
                     <CustomTabBar onClick={() => {
                        setTransport(1);
                    }} selected={selectedTransport === 1} title="Domestic Transport" icon={domestic} />
              { configStore?.currentSelectedEntity?.isTrackMyBooking === "Yes" &&  <CustomTabBar onClick={() => {
                        setTransport(2);
                    }} selected={selectedTransport === 2} title="Track My Booking" icon={tracking} />}

                     </>

                    }
                 
                </>
                } */}
            </span>

            <span style={{ display: "flex", gap: 3 , flexDirection:"row", alignItems:'center'}}>
            {console.log('mkmkmk',configStore.currentSelectedBranch)}
            {/* <Button onClick={()=> {
                console.log('clicked');
                configStore.currentSelectedEntity = {entityId : ''} ;
                configStore.currentSelectedBranch={branchId : ''}} }>Clear Entity</Button> */}

           {configStore.currentSelectedEntity?.entityId && <>
            <CustomMenu onClickHandler={async(i)=>{
                console.log("status",loginStore.userRec?.companyList[i]?.status === "DEACTIVATED");
                    if (loginStore.userRec?.companyList[i]?.status === "DEACTIVATED") {
                        let obj = {
                            cb : loginStore.userRec?.companyList[i]?.customer?.crossBorder?.relationshipManager,
                            dt:loginStore.userRec?.companyList[i]?.customer?.domesticTransport?.relationshipManager
                        }
                   await AguserApi.getProfile(obj,(data)=>{

                        return  configStore.setModalMessage(<div>Note: Your Entity is Deactivated and you have limited access rights. Please get in touch with your Agraga Relationship Manager to access all features.

                       <div style={{display:"flex",margin:"1rem 0"}}>
                        {data?.CB_User?<><div  style={{width:"50%"}}>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>CB RM Name</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>Email</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>Phone NO</p>
                                    </div>
                        <div  style={{width:"50%"}}>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.CB_User?.firstName}</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.CB_User?.email}</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.CB_User?.phone}</p>
                        </div></>:null}
                                </div>
                       <div  style={{display:"flex"}}>
                       {data?.DT_User?<><div  style={{width:"50%"}}>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>DT RM Name</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>Email</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>Phone NO</p>
                                    </div>
                        <div  style={{width:"50%"}}>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.DT_User?.firstName}</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.DT_User?.email}</p>
                            <p style={{padding:".3rem",borderBottom:"1px solid #f2f2f2"}}>{data?.DT_User?.phone}</p>
                        </div></>:null}
                                </div>
                       {data?.PAT_User?<p style={{marginTop:"1rem",textAlign:"center"}}>Or Please Contact <span style={{fontWeight:900}}>{data?.PAT_User?.email}</span> for further details</p>:null}
                        </div>,"",false)
                        })

                    }
                    localStorage.setItem('ag_current_entity', JSON.stringify(loginStore?.userRec?.companyList[i]))
                    console.log("The company list ",loginStore?.userRec?.companyList)
                    configStore.currentSelectedEntity = loginStore.userRec?.companyList[i] ? loginStore.userRec?.companyList[i] : '' ;
                    if(loginStore.userRec?.companyList[i].branches.length > 1 || loginStore.userRec?.companyList[i].branches.length === 0 ){
                        configStore.currentSelectedBranch = {"branchId" : "ALL"}    
                    }else { 
                        configStore.currentSelectedBranch = loginStore.userRec?.companyList[i]?.branches[0]
                    }

                    // configStore.currentSelectedBranch = loginStore.userRec?.companyList[i].branches[0]
                    // newDashboardState.isProfileSelected = false;
                    if(newDashboardState.moveToHome){
                        newDashboardState.selectedTab = 0;
                        InitialitingDefaultStateForHome()
                      }

                      if(!configStore?.currentSelectedEntity?.isTrackMyBooking?.toLowerCase() === "yes"){
                        newDashboardState.moveToHome = false
                      }
                    setTransport(0);
                    // if(selectedTabIndex<0){
                    //     setTabIndex(0);
                    // }
                    // setTimeout(() => {
                    //     configStore.currentSelectedBranch = {branchId : "ALL"}
                    //     // setselectedBranch("ALL") 
                    //     // document.getElementById("custommenubranchId").onClickHandler(0)
                    // }, 3000);
            }} currentValue={configStore.currentSelectedEntity?.entityName || 'NA'} title="Entity" items={loginStore?.userRec?.companyList?.map((company)=>company?.entityName)} />
                       <CustomMenu 
                       id="custommenubranchId"
                       onClickHandler={(i)=>{
                        //         localStorage.setItem('ag_current_entity', JSON.stringify(loginStore.userRec.companyList[i]))
                        //         console.log("The company list ",loginStore.userRec.companyList)
                        //         configStore.currentSelectedEntity = loginStore.userRec?.companyList[i];
                        //         newDashboardState.isProfileSelected = false;
                        //         setTransport(0);
                        //         if(selectedTabIndex<0){
                        //             setTabIndex(0);
                        //         }
                        // }
                  console.log("lok", i) ;
                  if(newDashboardState.moveToHome){
                    newDashboardState.selectedTab = 0;
                    InitialitingDefaultStateForHome();
                  }
                  configStore.currentSelectedBranch = branchList[i] ;
                //   if(User.userRec.aguserRec.selectgst){
                //     User.userRec.aguserRec.selectgst = branchList[i].gst ;
                //   }
                       }
            
            }  currentValue={(configStore.currentSelectedBranch?.state?.length >0)? configStore.currentSelectedBranch?.state+ "-" + (configStore.currentSelectedBranch?.gst.length > 0 ? configStore.currentSelectedBranch?.gst  : configStore.currentSelectedBranch?.tinNum ): configStore.currentSelectedBranch?.branchId?.length > 0? configStore.currentSelectedBranch?.branchId :  "NA"   } title="Branch" items={branchList.map((r) =>r.state? (r.state.split("/")[0]+ "-" + (r.gst.length > 0 ? r.gst : r.tinNum) ) : r.branchId)} />
           </>

           }

                {/* <CustomMenu title="Branch" items={["Chennai","Mumbai", "Bangalore","Cochin"]} /> */}
                {/* <NotificatioIcon /> */}
                <ProfileAvatar commonHandler={()=>{
                    setTabIndex(-1);
                }}  content={firstName+lastName} />
            </span>
        </Box>
        {/* <Box sx={{ backgroundColor: "#F0F0F0", height: "1px" }}></Box> */}
        <Box sx={{ display: "flex", flexDirection: 'row', margin: "0px 10px 0px 55px", height: "50%", position: 'relative' }}>
            {isActive && <span style={{ position: "absolute", bottom: "0", display: "inline-flex", gap: 10 }}>
                <Tabs  TabIndicatorProps={{
                    sx: { bgcolor: "#F4B14A"}
                }} style={{
                    height:"100%",
                }} value={selectedTabIndex} onChange={handleChange}>
                    {selectedTransport === 0 ? CrossBorederTabsDetails.map((tab, i) => <Tab  disableRipple value={i} sx={{ color:i===selectedTabIndex? "#F4B14A !important" :  "white", textTransform: "capitalize", fontFamily: "Figtree",fontSize: "14px" }} label={tab.title} />) :
                        //    DomesticTabDetails.map((tab, i) => <Tab disableRipple value={i} sx={{ color: "#555555", textTransform: "capitalize", fontFamily: "Figtree", fontWeight: "bold" , fontSize:"12px"}} label={tab.title} />)
                        selectedTransport === 1 ? DomesticTabDetails.map((tab, i) => <Tab disableRipple value={i} sx={{ color: "#555555", textTransform: "capitalize", fontFamily: "Figtree", fontSize: "12px" }} label={tab.title} />) :
                            TrackMyBookingDetails.map((tab, i) => <Tab disableRipple value={i} sx={{ color: "#555555", textTransform: "capitalize", fontFamily: "Figtree",  fontSize: "12px" }} label={tab.title} />)

                    }
                </Tabs>
            </span>}
        </Box>
    </>
}



export function  Fullscreen(){

    const { selectedTransportMode, isProfileSelected ,selectedTab} = useSnapshot(newDashboardState)
    let { changeCurrentMenu } = useGlobalState()
    const [selectedTabIndex, setTabIndex] = useState(selectedTab);
    const isActive  = loginStore.userRec.aguserRec.status === 'ACTIVE';
    const isNewReg  = loginStore.userRec.aguserRec.status === 'NEWREG';
    const isTempUser  = loginStore.userRec.aguserRec.status === 'TEMP USER';
    useEffect(()=>{
         console.log(" the enquiry store is changed for ",enquiryStore.current.enqRoute)
        console.log("the selected tab index is fdcgfcyfv txtgfcf hggdxtrc ",selectedTabIndex)
        // if(selectedTabIndex!==3){
        if (configStore.currentURL.startsWith("/contractsv2/view")) {
            truckLoader.open = true;
            const contractId = configStore.currentURL.split('/').at(3);
            if (contractId) {
                Api.getContractv2(contractId, (data) => {
                    if (loginStore.isClient()) {
                        let l = 1
                        loginStore.userRec?.companyList?.filter((e) => {
                            if (e._id == data.entityId) {
                                configStore.currentSelectedEntity = e
                                // let branch = e.branches.filter((x,a) => (
                                //     x.branchId === data.branchId))
                                // configStore.currentSelectedBranch = branch[0]
                                l = 0
                            }
                        })
                        if (l == 0) {
                            // changeCurrentMenu("Create Bookingv3")
                            breadCrumbStore.setBreadCrumbs([Constants.createbookings,Constants.chooseschedule],Constants.chooseschedule);
                            breadCrumbStore.id = data._id
                            newDashboardState.selectedTab = 3
                            setTabIndex(3)
                            enquiryStore.initializeWithConract(data)
                            //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
                            configStore.homescreenCurrentView = 'view'
                            configStore.scheduleOrCarrierform = 'schedule'
                            let stype = enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()
                            if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                            else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                            else configStore.homescreenCurrentShipmentType = 'Air'
                            configStore.homescreenCurrentShipmentScope = enquiryStore.current.shipmentScope
                            contractsStore.current = data;
        //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
       
                        } else {
                                window.location.href = window.location.origin+"/"
                        }
                    }
                    truckLoader.open = false;
                })
            }else{
                truckLoader.open = false;
            }
        }

        if (configStore.currentURL.startsWith("/visibility/view")) {
            const visibility = configStore.currentURL.split('/');
            let visibilityId = visibility[visibility?.length-1];
               console.log(" the visibility ",visibility," the id is ",visibilityId)
                if(visibilityId){
                    truckLoader.open = true;
                    ApiAgTable.getAllBooking(0,1,"","",{filter:"ALL",filter1:"ALL",search:visibilityId}).then(async(data)=>{
                        let lres = await data.json();
                        let booking = undefined;
                        if(lres.results1?.length>0){
                            booking = lres.results1[0];
                        }
                        truckLoader.open = false;
                        console.log(" teh booking is ",booking)
                        if(booking){
                            let l = 1
                            loginStore.userRec?.companyList?.filter((e) => {
                                if (e._id == booking.booking.entityId) {
                                    configStore.currentSelectedEntity = e
                                    let branch = e.branches.filter((x,a) => (
                                        x.branchId === booking.booking.branchId))
                                    configStore.currentSelectedBranch = branch[0]
                                    l = 0
                                }
                            })
                            if(l===0){
                                breadCrumbStore.setBreadCrumbs(["My Bookings","Booking"]);
                                newDashboardState.selectedTab = 5;
                                breadCrumbStore.id = visibilityId;
                                viewBookingStore.accordionToBeOpended = 1;
                        viewBookingStore.current = booking;
                        bookingsStore.current = booking;
                        enquiryStore.initializeWithConract(booking.booking.contract); 
                            }else{
                                 window.location.href = window.location.origin+"/"
                            }
                        }else{
                            window.location.href = window.location.origin+"/"
                        }
                    }).catch((error)=>{
                        truckLoader.open = false;
                        window.location.href = window.location.origin+"/"
                    })
                   
            }
        }

        if (configStore.currentURL.startsWith("/myactions")) {
            if (loginStore.userRec.userFound) {
                if (loginStore.isClient()) {
                    let nlink = decodeURI(configStore.currentURL)
                    nlink = nlink.split('ACTLINGACT')
                    if (nlink.length == 2) {
                        let action = nlink[1]
                        nlink = nlink[0].split('/')
                        if (nlink.length == 3) {
                            let bookingNum = nlink[2]
                            BookingsApi.getBooking(bookingNum, async (data) => {
                                let l = 1
                                loginStore.userRec?.companyList?.filter((e) => {
                                    if (e._id == data.contract.entityId) {
                                        configStore.currentSelectedEntity = e
                                        let branch = e.branches.filter((x,a) => (
                                            x.branchId === data.branchId))
                                        configStore.currentSelectedBranch = branch[0]
                                        l = 0
                                    }
                                })
                                if (l == 0) {
                                     let ret = await Taskapi.getTaskForAction(bookingNum, action)
                                    newDashboardState.selectedTab = 4
                                    contractsStore.current = data.contract
                                   
                                    if (ret.length > 0) {
                                            if(ret[ret.length-1]['customerStatus'] == 'COMPLETED'){
                                                ret[ret.length-1]['stage'] = 10
                                        }
                                            taskStore.current = ret[ret.length-1]
                                        taskStore.current.lastview = "CUSTOMER"
                                        taskStore.currentContainer = ''
                                            if(taskStore.current.isValid == undefined) taskStore.current.isValid = true
                                            if(taskStore.current.isValidfield == undefined) taskStore.current.isValidfield = []
                                        taskStore.myActionVisible = true
                                        taskStore.isViewAll = true
                                    }
                                } else {
                                        window.location.href = window.location.origin+"/"
                                }
                            })
                        }
                    }
                }
            }

            // }
        }
      
    },[])

    useEffect(()=>{
        setTabIndex(0);
    },[selectedTransportMode])
    useEffect(()=>{
   
        if(newDashboardState.selectedTab===0 && (breadCrumbStore.breadCrumbs.length===0 || breadCrumbStore.breadCrumbs[0]!=="Create Booking") ){
             InitialitingDefaultStateForHome();
        }

        setTabIndex(newDashboardState.selectedTab)
        
        if(aguserStore.aguserRec.firstName == "" && aguserStore.aguserRec.phone == ""){
            // newDashboardState.isProfileSelected = true;
            // breadCrumbStore.setBreadCrumbs(["Settings"],"Settings");
            newDashboardState.selectedTab = -1;
            newDashboardState.initialFlag = true
        }
    },[newDashboardState.selectedTab])
   return <Box sx={{height:"100%", padding:"8px", backgroundColor:"#EEEFF4",overflowY:"hidden",display:"flex",flexDirection:"column",gap:1}}>
    <Box sx={{ minHeight : "84px", maxHeight:"84px",display: "flex",flexDirection: "column", justifyContent: "space-between", boxShadow: '1px 1px 8px 1px rgba(0, 0, 0, 0.2)', borderRadius: "10px",  backgroundColor: "#2C358A" }}>
     <AgNavBar setTabIndex={(i)=>{
                newDashboardState.selectedTab = i;
                if(i==3){
                    changeCurrentMenu("Create Bookingv2")
                }
                setTabIndex(i);
            }} selectedTabIndex={selectedTabIndex} />
        </Box>
 <Box sx={{display:"flex",flexDirection:"column",height:"96%",overflowX:"hidden",overflowY:"auto",paddingRight:"10px"}}>
     {  (!isActive && !isTempUser) ?  <AcceptRegistration id={loginStore.email} /> 
      : isProfileSelected ? <Dashboard entityCreRefresh={setTabIndex}/>
    //   : isProfileSelected ? <ProfileSelection/>
       : 
       (aguserStore.aguserRec.firstName == "" && aguserStore.aguserRec.phone == "") ? <SettingsPage /> 
      : selectedTransportMode === 0 ?
        CrossBorederTabsDetails[selectedTabIndex]?.component
      : selectedTransportMode === 1 ? DomesticTabDetails[selectedTabIndex]?.component : TrackMyBookingDetails[selectedTabIndex]?.component}
      {(selectedTabIndex > 1 &&  configStore.currentSelectedEntity?.entityId === '') &&
        <EntityCreWarnPopup cancelfn={setTabIndex} dashboardTabIndex={selectedTabIndex} />
      }      
       </Box>
       </Box>
}