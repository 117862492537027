import { useSnapshot } from "valtio"
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { AgInput, AgSelect } from "../components/AgComponents";
import { Col3, Col6, Columns } from "../Controls";
import { AgmodalStore, bookingsStore, loginStore } from "../state/CommonState";
import { useState } from "react";
import Api from "../state/Api";

export default function Cartingorder(props) {
    useSnapshot(taskStore)
    useSnapshot(bookingsStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    let stage = task.stage
    if(bookingsStore.current!=undefined && bookingsStore.current.contract!=undefined  && bookingsStore.current.contract.shipmentType!=undefined){
        if(bookingsStore.current.contract.shipmentType.toLowerCase().indexOf('air')!=-1){
            if(task.co_data==undefined){
                task.co_data = [
                    {
                        label:"Booking Date",
                        type:"date",
                        "Booking Date":bookingsStore.current.bookingDate
                    },
                    {
                        label:"Clearance State",
                        type:"date"
                    },
                    {
                        label:"ETD",
                        type:"date"
                    },
                    {
                        label:"ETA",
                        type:"date"
                    },
                    {
                        label:"Hand Over Date",
                        type:"date"
                    },
                    {
                        label:"AWB Number",
                        type:"text"
                    }
                ]
            }
        }else{
            if(task.co_data==undefined){
                task.co_data = [
                    {
                        label:"Booking Date",
                        type:"date",
                        "Booking Date":bookingsStore.current.bookingDate
                    },
                    {
                        label:"SI Cut OFF",
                        type:"date"
                    },
                    {
                        label:"VGM Cut OFF",
                        type:"date"
                    },
                    {
                        label:"Gate Cut OFF",
                        type:"date"
                    },
                    {
                        label:"ETD @ POL",
                        type:"date"
                    },
                    {
                        label:"ETA @ FPOD",
                        type:"date"
                    }
                ]
            }
        }
    }
    let isDisabled = { disabled: true };
    if(stage==1){
        isDisabled = { disabled: false }
    }
    const submit = async ()=>{
        taskStore.current.stage = 2
        taskStore.current.pendingWith = 'CUSTOMER'
        await Taskapi.updateTask(taskStore.current)
        taskStore.triggerActionVisible = false
        taskStore.myActionVisible = false
        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
            AgmodalStore.pageVisible = false
            AgmodalStore.apiCall = true
        }
    }

        return (
            <div className="box">
                <Cartingorderadditem />
                <div className="columns is-multiline is-mobile is-align-items-center">
                {
                    task.co_data.map((v)=>{
                        let show = true
                        if(stage==2 && (v[v.label]==undefined || v[v.label].trim().length==0)){
                            show = false
                        }
                        if(show){
                            return <div className="column is-3">
                            <AgInput type={v.type} record={v} label={v.label} isDisabled={v.label=="Booking Date"?{ disabled: true }:isDisabled} name={v.label}/>
                            </div>
                        }
                    })
                }
                {
                    (stage==1)?<div className="column is-3">
                    <button className="button is-success"
                        onClick={async (e) => {
                            taskStore.cartingordernewitem = {
                                label:"",
                                type:"date"
                            }
                            taskStore.cartingModal = true
                        }}>Add Item</button> 
                    </div>:""
                }
                
                </div>
                
        <Columns>
        <Col3>
          </Col3>
          <Col3>
          </Col3>
          <Col3>
          </Col3>
          <Col3>
          {
            (stage==1)?
            <Columns>
          <Col6>
          <div className="has-text-left">
          <button className="button islink"
                    onClick={async (e) => {
                        await submit()
                    }}>Save</button>
          </div>
          </Col6>
          <Col6>
          <div className="has-text-right">
          <button className="button islink "
                    onClick={async (e) => {
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                            AgmodalStore.pageVisible = false
                        }
                    }}>cancel</button>
                    </div>
          </Col6>
          </Columns>:""
          }
          {
            (stage==2)?
            <div className="has-text-right">
          <button className="button islink "
                    onClick={async (e) => {
                        const callback = (data)=>{
                            if(data.success){
                                taskStore.current = data.data
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }
                        }
                        await Api.cartingorderAcknowledge(taskStore.current.bookingNum ,callback )
                    }}>Acknowledge</button>
                    </div>:""
          }
          </Col3>
        </Columns>
                
            </div>
        )
   

}
export function CartingorderTrigger(props) {
    useSnapshot(taskStore)
    const [disableButton,setDisableButton] = useState(false)
    return (
        <>
            <p>
                <button disabled={disableButton} className="button islink"
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'AGRAGA'
                        setDisableButton(true)
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}

export function Cartingorderadditem(props) {
    useSnapshot(taskStore)
    let modalClass = 'modal'
    if (taskStore.cartingModal) modalClass += ' is-active'
    return (
        <div className={modalClass} onClick={() => { taskStore.cartingModal = false }} style={{zIndex:1000000}}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >
                <div className="columns is-multiline is-mobile is-align-items-center">
                    <div className="column is-6">
                        <AgInput type="text" record={taskStore.cartingordernewitem} label="Label" name="label"/>
                    </div>
                    <div className="column is-2">
                        <AgSelect record={taskStore.cartingordernewitem} label="Type" name='type' dataList={["text","date","number"]}/>
                    </div>
                    <div className="column is-2">
                    <button class="button is-danger" 
                                                onClick={()=>{
                                                    console.log("+=",taskStore.cartingordernewitem)
                                                    if(taskStore.cartingordernewitem.label.trim().length>0){
                                                        taskStore.current.co_data.push({
                                                            label :taskStore.cartingordernewitem.label,
                                                            type :taskStore.cartingordernewitem.type,
                                                        })
                                                        taskStore.cartingModal = false
                                                    }
                                                }}
                                            >Save</button>
                                            
                    </div>
                    <div className="column is-2">
                    <button class="button is-warning" onClick={() => taskStore.cartingModal = false} >Cancel</button>
                    </div>
                </div>
                </section>
            </div>
        </div>
    )
}