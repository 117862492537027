import { useEffect, useState } from "react";
import routeIcon from "../assets/images/gis_route.svg";
import cargoDetail from "../assets/images/worldwide-shipping.svg";
import { SHIPMENT_SCOPE } from "../util/quotationUtil";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Cargo, Container, enquiryStore } from "../enquiry/enquiry-data";
import { Box, Button, Collapse, Typography } from "@mui/material";
import { AgMuiUn } from "../mui-components/AgAutoComplete";
import { AntSwitch } from "../mui-components/AntSwitch";
import { useSnapshot } from "valtio";
import { DetailsSelect2 } from "../mui-components/DetailsSelect";
import { containerTypeList, containerTypeListTC, getHazardousClassList, lclWeightForPort, maxDimensions, maxWeightPerContainerFcl, packageTypeList, packingGroupList, weightPerPackage } from "../StaticData";
import { DetailsText2 } from "../mui-components/DetailsText";
import { Quantity } from "../tailwind-components/Quantity";
import { ShippingDetailsV2 } from "./ShippingDetailsV2";
import { SelectedRadio, UnSelectedRadio } from "../tailwind-components/CustomRadioButton";
import { TextWithSelect } from "../mui-components/TextWithSelect";
import { AgMuiSelect2 } from "../mui-components/Controlled";
import LabeledTextField from "../mui-components/LabeledTextField";
import { Add, CheckBox } from "@mui/icons-material";
import deleteicon from "../assets/images/deletei.svg";
import containerdetail from "../assets/images/containerdetailsicon.svg";
import { breadCrumbStore, configStore, filtersOptions, filtersState, loginStore } from "../state/CommonState";
import PricingUtils from "../pricing/pricing.utils";
import { ENTITY_STATUS } from "../util/constants";
import Utils from "../util/Utils";
import MonApi from "../state/mongo-api";
import { MuiPopup } from "../mui-components/MuiPopUp";
import EnquiriesApi from "../state/enq-api";
import { loader } from "../util/loader";
import { TruckLoader, truckLoader } from "../mui-components/TruckLoader";
import { CopyText } from "../mui-components/CopyText";
import { ViewSchedule } from "./ViewSchedules";
import { BreadCrumb2 } from "../mui-components/BreadCrumb2";
import filterIcon from "../assets/images/filtericon.svg";
import accordionIcon from "../assets/images/accordionicon.svg";
import { CustomPopup } from "../tailwind-components/CustomPopup";
import { rtpStore } from "../pricing/pricing-store";
import { json } from "d3";
import EntityV2Api from "../state/entities-api";
import CargoHelper from "../util/cargo.helper";
import { Route } from "../v3-componets/AgRoute";
import { Details } from "../v3-componets/AgDetails";


const shipmentTYpes = ['LCL', 'FCL', 'Air'];

const shipmentScopes = Object.values(SHIPMENT_SCOPE);

function ControlledDetailSwitch({title,isChecked,formKey,onChange,control,disable=false}){
    return <span style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
        <p className="font-sm text-[#555555]">{title}</p>
        <Controller
          name={formKey}
          control={control}
          defaultValue={isChecked}
          render={({ field }) => (
            <AntSwitch
            disabled={disable}
              checked={isChecked}
              onChange={(e) => {
                field.onChange(e.target.checked); // Update the form state
                if (onChange) {
                  onChange(e.target.checked);
                }
              }}
              style={{ scale: "1" }}
            />
          )}
        />
    </span>
}


function ContainerDetails({form}){
    const { control, watch, getValues, resetField, setValue } = form;
    const { fields: containerDetails, append: appendContainer, remove: removeContainer } = useFieldArray({
        control: control,
        name: "containerdetails"
    })
    const [typeList, setTypeList] = useState(containerTypeList)
    const temperatureControlled = watch("temperatureControlled");
    const stuffingType = watch("stuffingType")
    const destuffingType = watch("destuffingType");
    const weightPerContainer = watch("containerdetails.0.unit")

    useEffect(()=>{
        for(let i = 1; i < containerDetails.length;i++){
               setValue(`containerdetails.${i}.unit`,weightPerContainer)
        }
    },[weightPerContainer])
    useEffect(() => {
        console.log("the tempereature controlled is ", temperatureControlled)
        if (temperatureControlled) {
            setTypeList(containerTypeListTC);
        // } else if (stuffingType.toLowerCase() !== "factory" || destuffingType.toLowerCase() !== "factory") {
        //     setTypeList(containerTypeList.slice(0, 4))
          
        } else {
            setTypeList(containerTypeList)
           
        }

    }, [temperatureControlled, stuffingType, destuffingType]);
    const validateGreaterThanZero = (value) => {
        return parseFloat(value) > 0 || 'Invalid Value';
    };
   return <div className="flex flex-col w-full rounded-md bg-white shadow-custom p-[18px] gap-[18px]">
   <div className="flex flex-row justify-between items-center">
   <div className="flex flex-row gap-[10px]">
        <img src={containerdetail}/>
        <p className="text-black font-bold">Container Details</p>
     </div>
     </div>
     <CargoTypes form={form} />
   <div className="w-full flex flex-col gap-2 items-end">
     <div className="flex flex-col w-full">
     <div className={`flex flex-row w-full h-[35px] items-center border-[0.5px] border-solid border-[#D4D4D4] border-b-0 px-[12px]`}>
          <FlexibleCenteredDiv>
              <TableHeading title="Container Type *" />   
          </FlexibleCenteredDiv>
          <FlexibleCenteredDiv>  <TableHeading title="Number of Containers *" /></FlexibleCenteredDiv>
       <FlexibleCenteredDiv>
        <HeadingWithSelect  options={["Kgs", "Tons", "Lbs"]} heading={"Weight Per Container *"} control={control} name={`containerdetails.0.unit`} />
        </FlexibleCenteredDiv>
        {containerDetails.length > 1 && <div className="w-[35px] h-full"></div>}
        </div>
        {containerDetails.map((containerdetail,i)=><div className={`flex flex-row w-full py-[8px] items-center px-[12px] ${i%2===0?"bg-[#F3F3F3]":"bg-white border-[0.5px] border-solid border-[#D4D4D4]"}`}>
        <FlexibleCenteredDiv >
          <AgMuiSelect2  key={containerdetail.id} style={{height:"25px",width:"170px"}} onBlur={()=>{
                    }} rules={{ required: "Required" }}  size="small" fontSize="12px" options={typeList}  control={control} name={`containerdetails.${i}.containerType`} onChangeHandler={(e)=>{
                        const containerTypes =["20'OT (Out-gauge)","40'OT (Out-gauge)","20'FR (Out-gauge)","40'FR (Out-gauge)"]
                        if(e!==''){
                            setValue('showCargoDetailsOutGuage',containerTypes.includes(e))
                        }    else{
                            setValue('showCargoDetailsOutGuage',false)
                        }

                    }} />
          </FlexibleCenteredDiv>
          <FlexibleCenteredDiv >
            <Quantity key={containerdetail.id}  control={control} name={`containerdetails.${i}.numContainers`} rules={{ required: "Required", validate: validateGreaterThanZero }} />
          </FlexibleCenteredDiv>
          <FlexibleCenteredDiv>
            <TextWithSelect key={containerdetail.id} options={["Kgs", "Tons", "Lbs"]} control={control} name={`containerdetails.${i}.wtPerContainer`} unit={weightPerContainer} rules={{required:"Required" ,validate: (value)=>{
                        let containerType = getValues(`containerdetails.${i}.containerType`);
                       
                           if(parseFloat(value) <= 0){
                              return "Invalid value"
                           }
                           if(!containerType){
                            return
                        }
                        let maxValue = maxWeightPerContainerFcl[containerType][weightPerContainer.toLowerCase()];

                           if (parseFloat(value) >maxValue) {
                            return `value can't be greater than ${maxValue} ${weightPerContainer}.`
                           }
                           return;

                        }
                    }} />
          </FlexibleCenteredDiv>
          { containerDetails.length > 1 && 
           <div onClick={()=>{
            removeContainer(i);
           }} className="w-[35px] h-full flex items-center justify-center cursor-pointer">
<img src={deleteicon}/>
           </div>
                }
        </div>)}
     </div>
     <button onClick={()=>{
             let newContainer = new Container();
             newContainer.unit = weightPerContainer;
             appendContainer({ ...newContainer })
        }} type="button" className="bg-[#F7F7F7] border-[1px] border-solid border-[#E8E8E8] h-[35px] w-[110px] rounded-[6px]">
            <div className="flex h-full w-full flex-row gap-[5px] justify-center items-center font-bold">
                <Add sx={{fontSize:"22px",color:"#2C358A"}}/>
                <p className="text-grey">Add Row</p>
            </div>
        </button>
   </div>
   </div>
}


function LabeledRadioButton({selected=false,label,onClick}){
    return <div onClick={()=>{
        if(onClick){
            onClick();
        }
    }} className="flex flex-row gap-[10px] items-center  hover:cursor-pointer">
         {selected ? <SelectedRadio/> : <UnSelectedRadio/>}
         <p className={`${selected?"text-blue-dark font-bold":"text-grey"} font-roboto`}>{label}</p>
    </div>
}

function CargoTypes({form}){
    const enquiry = useSnapshot(enquiryStore);
    const { control, register, formState: { errors }, handleSubmit, resetField, watch, setValue, reset } = form;
    let hazardous = watch("hazardous");
    let nonstackable = watch("nonStackable");
    let temperatureControlled = watch("temperatureControlled");
    let minTemperature = watch("minTemperature");
    let maxTemperature = watch("maxTemperature");
    const stuffingType = watch("stuffingType")
    const destuffingType = watch("destuffingType")
    useEffect(()=>{
        resetField("hazardous",false);
        resetField("nonStackable",false);
        resetField("temperatureControlled",false);
        resetField("minTemperature","");
        resetField("maxTemperature","")
        resetField("stuffingType","Factory")
        resetField("destuffingType","Factory")
    },[])
    useEffect(()=>{
        if(stuffingType==="Dock" && destuffingType === "Dock"){
            setValue("temperatureControlled",false)
            enquiryStore.current.temperatureControlled = "No"
        }
     },[stuffingType,destuffingType])
 
     useEffect(() => {
         enquiryStore.current.hazardous = hazardous ? 'Yes' : "No"
         if(!hazardous){
             enquiryStore.current.quoteClass = "";
             enquiryStore.current.packingGroup = "";
             enquiryStore.current.unnumber = "";
             resetField("quoteClass")  
             resetField("packingGroup") 
             resetField("unnumber")
         }
     }, [hazardous])
 
     useEffect(() => {
         enquiryStore.current.nonStackable = nonstackable ? 'Yes' : "No"
     }, [nonstackable])
 
     useEffect(() => {
         enquiryStore.current.temperatureControlled = temperatureControlled ? 'Yes' : "No"
         if(!temperatureControlled){
              if(enquiry.current.shipmentType.toLowerCase() === 'fcl'){
                 enquiryStore.current.temperature = 0;
                 enquiryStore.current.cargoValue = 0;
                 resetField("cargoValue");
                 resetField("temperature");
              }else{
                 enquiryStore.current.minTemperature = 0;
                 enquiryStore.current.maxTemperature = 0;
                 resetField("minTemperature");
                 resetField("maxTemperature");
              }
         }
     }, [temperatureControlled])
 
 const showStackForFcl = ()=>{
     let origin = enquiryStore.current.shipmentScope.split("-")[0];
        
     let destination = enquiryStore.current.shipmentScope.split("-")[2];
     
         if((origin?.toLowerCase()==="door" && stuffingType.toLowerCase() === "dock" ) || (destination?.toLowerCase()==="door" && destuffingType.toLowerCase() === "dock" ) || (enquiryStore.current.shipmentScope!=="Port-to-Port" && stuffingType.toLowerCase() === "dock" && destuffingType.toLowerCase() === "dock") ){
            return true
         }
         return false
 }
    return  <div className="w-full flex flex-row justify-between flex-wrap gap-2.5">
    {!temperatureControlled && <ControlledDetailSwitch control={control} isChecked={hazardous} register={register} formKey={"hazardous"} title={"Hazardous"} />}
    {(enquiry.current.shipmentType.toLowerCase() === "lcl" || enquiry.current.shipmentType.toLowerCase() === 'air')  &&  <ControlledDetailSwitch control={control} isChecked={nonstackable} register={register} formKey={"nonStackable"} title={"Non-Stackable"}  />}
    {(enquiry.current.shipmentType.toLowerCase() === "fcl" || enquiry.current.shipmentType.toLowerCase() === 'air') && !hazardous &&  ( stuffingType!=="Dock" || destuffingType !== "Dock" )  && <ControlledDetailSwitch control={control} isChecked={temperatureControlled} onChange={(value)=>{
           setValue("containerdetails",[new Container()])
    }} register={register} formKey={"temperatureControlled"} title={"Temperature Controlled"} />}
    {hazardous && <DetailsSelect2 control={control} name={"quoteClass"} label="Class" options={getHazardousClassList(enquiry.current.shipmentType.toUpperCase())} />}
    {hazardous && <DetailsSelect2 control={control} name={"packingGroup"} label="Packing Group" options={packingGroupList} />}
    {hazardous && <DetailsText2
        label={"UN Number"} 
            fontSize="12px" size="small" fullwidth control={control} name={"unnumber"} rules={{
                validate:(value)=> {
                    if(value?.length!=4){
                        return 'UN number must be 4 numbers'
                    }
                }
            }}  />
}
  {temperatureControlled && !hazardous && enquiry.current.shipmentType.toLowerCase() === 'fcl' &&
        <>
                <DetailsText2 label="Temperature" fontSize="12px" size="small" fullwidth control={control} name={"temperature"}
                    rules={{
                        pattern: {
                            value: /^-3[0-5]$|^-?[0-2]?[0-9]$|^30$/,
                            message: 'Temperature must be between -35 to 30'
                        },
                    }}
                    allowNegative={true} />

                <DetailsText2 label="Cargo Value(USD)" fontSize="12px" size="small" fullwidth control={control} name={"cargoValue"}
                    rules={{
                        validate:(value)=> {
                            if(value<=0){
                                return "Invalid value"
                            }
                            if(value?.length!=5){
                                return 'Cargo Value must be 5 numbers'
                            }
                        }
                    }} />
        </>
    }
     {temperatureControlled && !hazardous && enquiry.current.shipmentType.toLowerCase() === 'air' &&
            <>
               
                    <DetailsText2 label="Minimum Temperature" fontSize="12px" size="small" fullwidth control={control} name={"minTemperature"} rules={{
                        pattern: {
                            value: /^(\+|-)?([0-9]|[1-2][0-9]|3[0-5])$/,
                            message: 'Temperature must be between -35 to 35'
                        },
                        validate: (value) => {
                            if (value <= maxTemperature) {
                                return true
                            } else {
                                return "Minimum temp should not be greater than Maximum temperature"
                            }
                        }

                    }} allowNegative={true} />
                
                    <DetailsText2 label="Maximum Temperature" fontSize="12px" size="small" fullwidth control={control} name={"maxTemperature"}
                        rules={{
                            pattern: {
                                value: /^(\+|-)?([0-9]|[1-2][0-9]|3[0-5])$/,
                                message: 'Temperature must be between -35 to 35'
                            },
                            validate: (value) => {
                                if (value >= minTemperature) {
                                    return true
                                } else {
                                    return "Maximum temp should not be less than Minimum temperature"
                                }
                            }

                        }} allowNegative={true} />
            </>
        }
          {enquiry.current.shipmentType.toLowerCase() === 'fcl' && <>
           <StuffingTypeForFcl title="Stuffing Type?" onChange={(e)=>{
               if(e.target.checked){
                   setValue("stuffingType","Dock");
                   setValue("containerdetails",[new Container()])
               }else{
                setValue("stuffingType","Factory");
                setValue("cargodetails",[new Cargo()])
                enquiryStore.current.cargoDetails = [new Cargo()];
               }
           }} />
           <StuffingTypeForFcl title="De-Stuffing Type?" onChange={(e)=>{
               if(e.target.checked){
                   setValue("destuffingType","Dock");
                   setValue("containerdetails",[new Container()])
               }else{
                setValue("destuffingType","Factory");
                setValue("cargodetails",[new Cargo()])
                enquiryStore.current.cargoDetails = [new Cargo()];
               }
           }} />
        </>
        }
        </div>
}

function TableHeading({title}){
    return <p className="text-sm text-blue-dark font-bold font-barlow">{title}</p>
}


function FlexibleCenteredDiv({children,className}){
    return <div className={`flex items-center justify-center flex-1 ${className}`}>{children}</div>
}

const LabelStyle = "flex items-center justify-center border-l-[0.5px] border-l-solid border-l-[#D4D4D4] font-bold text-sm min-w-[24px] h-full"

const DimensionLabelStyle = {height:"25px",width:"80px",padding:0,fontSize:"13px"}

function HeadingWithSelect({heading,control,name,options,values,onDropdownChange}){
    return <div className="flex flex-row items-center justify-center gap-2.5 flex-1 ">
  <TableHeading title={heading} />
            <AgMuiSelect2 onChangeHandler={()=>{
                if(onDropdownChange){
                    onDropdownChange();
                }
            }} padding={0} control={control} name={name} size="small"  variant="standard" disableUnderline={true} values={values} options={options } fontColor="#555555" iconColor="#555555"  style={{ fontSize: "12px", alignItems:"center", height:"25px",
                // minWidth:"68px",
                fontSize:"13px",
                fontWeight:"bold",
                color: "#555555",
                borderLeft :0,
                padding:0,
                paddingRight: "0px !important",
                backgroundColor:"white", }} />
    </div>
}

function CargoDetails({form}){
  
    const { control, watch, getValues, trigger, setValue } = form;
    const { fields: cargodetails, append: appendCargo, remove: removeCargo } = useFieldArray({
        control: control,
        name: "cargodetails"
    })
    const mode = enquiryStore.current.shipmentType.toLowerCase();
    const [showAdvancedCargoDetails,setAdvancedCargoDetails] = useState(false);
    const [changeToDimension,setChangeToDimension] = useState(false);
    const [total,setTotal] = useState({ totNumPackages : 0,
        totTotalWeight : 0,
        totTotalVolume : 0,
        totChargeableWeight : 0}) 
    let isDimension = false;
    const isFBA = watch("isFba");
    const cargodetailsFromForm = watch("cargodetails");
    let nonstackable = watch("nonStackable");
    const outGuage = watch("showCargoDetailsOutGuage");
    const totalWeight = watch("cargodetails.0.totalWeight.unit");
    const totalVolume = watch("cargodetails.0.totalVolume.unit");
    const weight = watch("cargodetails.0.weight.unit");
    useEffect(()=>{
        if(mode==="lcl" && enquiryStore.current.shipmentScope==="Port-to-Port"){
            setAdvancedCargoDetails(true);
        }else{
            setAdvancedCargoDetails(false);
        }

        
    },[enquiryStore.current.shipmentScope,enquiryStore.current.shipmentType])

   useEffect(()=>{
    calculateWeightAndVolume();
   },[JSON.stringify(cargodetailsFromForm)])

    useEffect(() => {
        enquiryStore.current.nonStackable = nonstackable ? 'Yes' : "No"
    }, [nonstackable])
    const dimensionsUnit = watch("cargoDimensionUnit");
    
    const stuffingType = watch("stuffingType")
    const destuffingType = watch("destuffingType");

const obj = {
    "stuffingType":stuffingType,
    "destuffingType":destuffingType
}



  useEffect(()=>{
      for(let i = 1; i < cargodetails.length;i++){
             setValue(`cargodetails.${i}.weight.unit`,weight)
      }
      calculateWeightAndVolume();
  },[weight])

  useEffect(()=>{
    for(let i = 1; i < cargodetails.length;i++){
           setValue(`cargodetails.${i}.totalWeight.unit`,totalWeight)
    }
    calculateWeightAndVolume();
},[totalWeight])

useEffect(()=>{
    for(let i = 1; i < cargodetails.length;i++){
           setValue(`cargodetails.${i}.totalVolume.unit`,totalVolume)
    }
    calculateWeightAndVolume();
},[totalVolume])

useEffect(()=>{
    for(let i = 0; i < cargodetails.length;i++){
        setValue(`cargodetails.${i}.dimensions.unit`,dimensionsUnit)
 }
    calculateWeightAndVolume();
},[dimensionsUnit]);

    const validateGreaterThanZero = (value) => {
        return parseFloat(value) > 0 || 'Required';
    };

    if ((mode === "lcl" && enquiryStore.current.shipmentScope.includes("Door")) || mode === "air" || mode === "fcl" || changeToDimension ) {
        isDimension = true;
    }

    const showStackForFcl = ()=>{
        let origin = enquiryStore.current.shipmentScope.split("-")[0];
           
        let destination = enquiryStore.current.shipmentScope.split("-")[2];
        
            if((origin?.toLowerCase()==="door" && stuffingType.toLowerCase() === "dock" ) || (destination?.toLowerCase()==="door" && destuffingType.toLowerCase() === "dock" ) || (enquiryStore.current.shipmentScope!=="Port-to-Port" && stuffingType.toLowerCase() === "dock" && destuffingType.toLowerCase() === "dock") || outGuage){
               return true
            }
            return false
    }

    const calculateWeightAndVolume = ()=>{
       let totals = calcCargoDetails(getValues("cargodetails"),isDimension,dimensionsUnit);
       setTotal({...totals});
    }

    return  <div className="flex flex-col w-full rounded-md bg-white shadow-custom p-[18px] gap-[18px]">
    <div className="flex flex-row justify-between items-center">
    <div className="flex flex-row gap-[10px]">
         <img src={cargoDetail}/>
         <p className="text-black font-bold">{Utils.labelForOutGauge3({shipmentType:mode,shipmentScope:enquiryStore.current.shipmentScope,obj:obj})?"Cargo Details for Out-Gauge Containers":"Cargo Details"}</p>
      </div>
     {showAdvancedCargoDetails && <div className="flex flex-row gap-1 items-center">
        <p className="text-black font-roboto">Advanced cargo details</p>
        <AntSwitch checked={changeToDimension} onChange={(e)=>{
                setChangeToDimension(e.target.checked);
                setValue("cargodetails",[new Cargo()]);
        }} />
      </div>}
      </div>
      {showStackForFcl() && <ControlledDetailSwitch control={control} isChecked={nonstackable} formKey={"nonStackable"} title={"Non-Stackable"}/> }
     { mode.toLowerCase() !== "fcl" && <CargoTypes form={form} />}
     <div className="w-full flex flex-col gap-2 items-end">
       <div className="flex flex-col w-full">
       <div className={`flex flex-row w-full h-[35px] items-center border-[0.5px] border-solid border-[#D4D4D4] border-b-0 px-[12px]`}>
          <FlexibleCenteredDiv>
              <TableHeading title="Package Type*" />   
          </FlexibleCenteredDiv>
          <FlexibleCenteredDiv>  <TableHeading title="Quantity *" /></FlexibleCenteredDiv>
        <FlexibleCenteredDiv> 
            <HeadingWithSelect key={isDimension} onDropdownChange={()=>{
                // calculateWeightAndVolume();
            }} heading={(isDimension)? mode === "lcl" ? "Weight per package *" : "Weight Per Piece *" : "Total Weight *"} options={["Kgs", "Tons", "Lbs"]} name={isDimension ? `cargodetails.0.weight.unit` : `cargodetails.0.totalWeight.unit`} control={control} />
            {/* <TableHeading title={ (isDimension)? mode === "lcl" ? "Weight per package *" : "Weight Per Piece *" : "Total Weight *"} /> */}
            </FlexibleCenteredDiv>
        <div className={`flex flex-col flex-1`}>    

       {isDimension?<div className="flex flex-row items-center justify-center w-[300px] gap-1 flex-1 ">
            <TableHeading title="Dimensions* x" />
            <AgMuiSelect2 onChangeHandler={()=>{
                // calculateWeightAndVolume();
            }}  padding={0} control={control} name={"cargoDimensionUnit" } size="small"  variant="standard" disableUnderline={true} values={ ['Cms', 'MM', 'Inches', "Meters", 'Feet']} options={['Cms', 'MM', 'Inches', "Meters", 'Feet'] } fontColor="#555555" iconColor="#555555"  style={{ fontSize: "12px", alignItems:"center", height:"25px",
                // minWidth:"68px",
                fontSize:"13px",
                fontWeight:"bold",
                color: "#555555",
                borderLeft :0,
                padding:0,
                // paddingRight: "8px",
                backgroundColor:"white", }} />
        </div>: <FlexibleCenteredDiv>
            <HeadingWithSelect onDropdownChange={()=>{
                calculateWeightAndVolume();
            }} control={control} name={"cargodetails.0.totalVolume.unit"} options={['CBM', 'cu ft']} heading={"Total Volume*"}/>
            </FlexibleCenteredDiv>}
        </div>
        {cargodetails.length > 1 && <div className="w-[35px] h-full"></div>}
        </div>
       {cargodetails.map((cargodetail,i)=> <div className={`flex flex-row w-full py-[8px] items-center px-[12px] ${i%2===0?"bg-[#F3F3F3]":"bg-white border-[0.5px] border-solid border-[#D4D4D4]"}`}>
          <FlexibleCenteredDiv >
          <AgMuiSelect2 key={cargodetail.id} style={{height:"25px",width:"170px"}} onChangeHandler={(value)=>{
            if( isFBA && value==="Loose Cartons"){
                 setValue("showPalletLabel",true)
            }else{
                setValue("showPalletLabel",false)
            }
          }} onBlur={()=>{
                    }} rules={{ required: "Required" }}  size="small" fontSize="12px" options={isFBA?["Pallet","Loose Cartons"]:packageTypeList} control={control} name={`cargodetails.${i}.packageType`} />
          </FlexibleCenteredDiv>
          <FlexibleCenteredDiv >
            <Quantity key={cargodetail.id}  control={control} name={`cargodetails.${i}.quantity`} rules={{ required: "Required", validate: validateGreaterThanZero }} />
          </FlexibleCenteredDiv>
          <FlexibleCenteredDiv>
            { isDimension ?  <TextWithSelect   name={`cargodetails.${i}.weight.value` } control={control} key={cargodetail.id+showAdvancedCargoDetails} unit={weight}  rules={{
                        required: "Required", validate: (value) => {
                            let isValue = validateGreaterThanZero(value);
                            if (typeof isValue === "string") {
                                return isValue
                            } 
                            else if(mode!=="fcl") {
                                let unit =  getValues(`cargodetails.${i}.weight.unit`);
                              
                                let maxValue = weightPerPackage[mode][unit.toLowerCase()];
                                if (parseFloat(value) > maxValue) {
                                    // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                    return `value can't be greater than ${maxValue + " " + unit} `;
                                }
                            }
                        }
                    }} /> : <TextWithSelect  unit={totalWeight} key={cargodetail.id+showAdvancedCargoDetails}  options={["Kgs", "Tons", "Lbs"]} control={control} name={`cargodetails.${i}.totalWeight.value`} selectName={`cargodetails.${i}.totalWeight.unit`} rules={{ required: "Required", validate: (value) => {
                        let isValue = validateGreaterThanZero(value);
                        const totalWeight =  getValues( `cargodetails.${i}.totalWeight.unit`);
                        if (typeof isValue === "string") {
                            return isValue
                        } 
                        else if(mode!=="fcl") {
                            let maxValue = weightPerPackage[mode][totalWeight.toLowerCase()]
                            if (parseFloat(value) > maxValue) {
                                // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                return `value can't be greater than ${maxValue + " " + totalWeight} `;
                            }
                        }
                    } }} /> }
          </FlexibleCenteredDiv>
          {isDimension ? <FlexibleCenteredDiv className={`flex-none w-[300px]`}>
            <div key={cargodetail.id} className="flex flex-row w-full gap-1">
                <LabeledTextField   textfieldStyles={DimensionLabelStyle} rules={{
                        required: "Required", validate: (value) => {
                            let isValue = validateGreaterThanZero(value);
                            if (typeof isValue === "string") {
                                return isValue
                            }
                             else if(mode!=="fcl"){
                               
                                let maxValue = maxDimensions[mode][dimensionsUnit.toLowerCase()].length
                                
                                if (value > maxValue) {
                                    // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                    return `value can't be greater than ${maxValue+" "+dimensionsUnit} `;
                                }
                            }
                        }
                    }} control={control} name={`cargodetails.${i}.dimensions.length`} endAdornment={<div className={LabelStyle}>L</div>}  />
                <p>x</p>
                <LabeledTextField  textfieldStyles={DimensionLabelStyle} rules={{
                        required: "Required", validate: (value) => {
                            let isValue = validateGreaterThanZero(value);
                            if (typeof isValue === "string") {
                                return isValue
                            } 
                            
                            else if(mode!=="fcl"){
                               
                                let maxValue = maxDimensions[mode][dimensionsUnit.toLowerCase()].width
                                
                                if (value > maxValue) {
                                    // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                    return `value can't be greater than ${maxValue+" "+dimensionsUnit} `;
                                }
                            }
                        }
                    }} control={control} name={`cargodetails.${i}.dimensions.width`} endAdornment={<div className={LabelStyle}>W</div>}  />
                <p>x</p>
                <LabeledTextField textfieldStyles={DimensionLabelStyle} rules={{
                        required: "Required", validate: (value) => {
                            let isValue = validateGreaterThanZero(value);
                            if (typeof isValue === "string") {
                                return isValue
                            } 
                            else if(mode!=="fcl"){
                               
                                let maxValue = maxDimensions[mode][dimensionsUnit.toLowerCase()].height
                                
                                if (value > maxValue) {
                                    // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                    return `value can't be greater than ${maxValue+" "+dimensionsUnit} `;
                                }
                            }
                        }   
                    }} control={control} name={`cargodetails.${i}.dimensions.height`} endAdornment={<div className={LabelStyle}>H</div>}  />
            </div> </FlexibleCenteredDiv> :<FlexibleCenteredDiv> <TextWithSelect  unit={totalVolume} key={cargodetail.id}  options={['CBM', 'cu ft']}
            rules={{ required: "Required", validate: (value) => {
                let isValue = validateGreaterThanZero(value);
                const totalVolume = getValues(`cargodetails.${i}.totalVolume.unit`)
                if (typeof isValue === "string") {
                    return isValue
                } 
                else if(mode!=="fcl"){
                   
                    let maxValue = lclWeightForPort["totalvolume"][totalVolume.toLowerCase()]
                    
                    if (value > maxValue) {
                        // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                        return `value can't be greater than ${maxValue+" "+totalVolume} `;
                    }
                }
            } }}
            selectName={`cargodetails.${i}.totalVolume.unit`} control={control} name={`cargodetails.${i}.totalVolume.value`} /></FlexibleCenteredDiv>}
           { cargodetails.length > 1 && 
           <div onClick={()=>{
            const currentType = getValues(`cargodetails.${i}.packageType`);
            if(currentType==="Loose Cartons"){
                setValue("showPalletLabel",false)
            }
            removeCargo(i);
           }} className="w-[35px] h-full flex items-center justify-center cursor-pointer">
<img src={deleteicon}/>
           </div>
                    // : <CenteredBox sx={{width:"34px"}}/>
                }
        </div>)}

       </div>
       <div className="flex flex-row justify-between items-center w-full">
         <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Total Weight:</p><p className="text-yellow font-bold font-barlow">{isNaN(total.totTotalWeight)?0:total.totTotalWeight} Kgs</p></div>
         { !(enquiryStore.current.shipmentType.toLowerCase()==="air") && <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Total Volume:</p><p className="text-yellow font-bold font-barlow">{isNaN(total.totTotalVolume)?0:total.totTotalVolume} CBM</p></div>}
         { enquiryStore.current.shipmentType.toLowerCase()==="air" && <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Chargable Weight:</p><p className="text-yellow font-bold font-barlow">{isNaN(total.totChargeableWeight)?0:total.totChargeableWeight} Kgs</p></div>}

       <button onClick={()=>{
            let newCargo = new Cargo();
            newCargo.totalVolume.unit = totalVolume;
                newCargo.totalWeight.unit = totalWeight;
                newCargo.weight.unit = weight;
            appendCargo({ ...newCargo });
        }} type="button" className="bg-[#F7F7F7] border-[1px] border-solid border-[#E8E8E8] h-[35px] w-[110px] rounded-[6px]">
            <div className="flex h-full w-full flex-row gap-[5px] justify-center items-center font-bold">
                <Add sx={{fontSize:"22px",color:"#2C358A"}}/>
                <p className="text-grey">Add Row</p>
            </div>
        </button>
       </div>
    </div>
    </div>
}

// function Details({form}){
//     const enquiry = useSnapshot(enquiryStore);
//     const mode = enquiry.current.shipmentType;
//     const {watch} = form;
//     const stuffing = watch("stuffingType")
//  const deStuffing = watch("destuffingType");
//   const isFBA = watch("isFba")
//  const outGuage = watch("showCargoDetailsOutGuage")

   
//   let showCargoDetail = false;
   
//   if(mode.toLowerCase()==="lcl" || mode.toLowerCase()==="air"){
//     showCargoDetail = true;
//   }

//   if(mode.toLowerCase() === "fcl"){
//     let origin = enquiryStore.current.shipmentScope.split("-")[0];
       
//     let destination = enquiryStore.current.shipmentScope.split("-")[2];
    
//         if((origin?.toLowerCase()==="door" && stuffing.toLowerCase() === "dock" ) || (destination?.toLowerCase()==="door" && deStuffing.toLowerCase() === "dock" )){
//            showCargoDetail = true;
//         }
//    }

 
//     return <div className="flex flex-col grow w-[876px] gap-2.5">
//        { mode.toLowerCase() === "fcl"  && <ContainerDetails form={form} />}
//        { (showCargoDetail||outGuage) && <CargoDetails form={form} />}
//        {isFBA && <ServiceOptIns form={form} />}
//     </div>
// }

function StuffingTypeForFcl({title,onChange}){
    const [stuffing,setStuffingType] = useState(false)
    return <div className="flex flex-row gap-2.5 items-center">
        <p className="text-sm text-[#4BA7DD] font-bold">{title}</p>
        <p className="text-sm text-grey">Factory</p>
        <AntSwitch value={stuffing} onChange={(e)=>{
            setStuffingType(e.target.checked);
             if(onChange){
                onChange(e);
             }
        }}/>
         <p className="text-sm text-grey">Cfs</p>
    </div>
}

const clearResults = ()=>{
    filtersState.initialize();
}

function ServiceOptIns({form}){
    const {control,watch} = form;
    const ior = watch("ior");
    const occ = watch("occ");
    const survey = watch("survey");
    const storageAtDestination = watch("storageAtDestination");
    const palletizationandlabelling = watch("palletizationandlabelling");
    const showPalletLabel = watch("showPalletLabel");
    return <div className="flex flex-col w-full rounded-md bg-white shadow-custom p-[18px] gap-[18px]">
        <div className="flex flex-row justify-between items-center">
    <div className="flex flex-row gap-[10px]">
         <img src={cargoDetail}/>
         <p className="text-black font-bold">Service Opt-ins</p>
      </div>
      </div>
                <div className="grid grid-cols-3 place-content-between gap-2.5">
                    <ControlledDetailSwitch control={control} formKey="ior" isChecked={ior} title="IOR Required?" onChange={clearResults} />
                    <ControlledDetailSwitch control={control} formKey="occ" isChecked={occ} title="Origin Customs Clearance" onChange={clearResults} />
                    <ControlledDetailSwitch control={control} formKey="survey" isChecked={survey} title="Survey" onChange={clearResults} />
                    {showPalletLabel && <ControlledDetailSwitch disable={palletizationandlabelling} control={control} formKey="palletizationandlabelling" isChecked={true} title="Palletization and labeling" onChange={clearResults} />}
                    <ControlledDetailSwitch onChange={clearResults} control={control} formKey="storageAtDestination" isChecked={storageAtDestination} title="Storage at destination?" />
                    {storageAtDestination && <DetailsText2
        label={"Number of days required"} 
            fontSize="12px" size="small" fullwidth control={control} name={"days"} 
              />}
                </div>
    </div>
}

// function Route({form}){
//     useSnapshot(enquiryStore);
//     console.log(" the log of shipment scope is ",enquiryStore.current.shipmentScope)
//     const { control, watch, register, setValue, handleSubmit, reset,resetField, clearErrors, formState, trigger, getFieldState } = form;
//     const shipmentScope = enquiryStore.current.shipmentScope;
//     const shipmentType = watch("mode");
//     const isFba = watch("isFba");
  
//     useEffect(()=>{
//         if(shipmentScope==="Port-to-Port" || shipmentScope==="Door-to-Port"){
//               setValue("isFba",false);
//         }
//         setValue("showCargoDetailsOutGuage",false);
//         setValue("scope",shipmentScope);
//     },[shipmentScope])

 
   
//     return  <div className="flex flex-col min-h-[304px] w-[431px]">
//     <div className="flex flex-col min-h-[304px] max-h-max w-full rounded-md bg-white shadow-custom p-[18px] gap-[16px]">
//         <div className="flex flex-row justify-between items-center">
//         <div className="flex flex-row gap-[10px]">
//            <img src={routeIcon}/>
//            <p className="text-black font-bold">Route</p>
//         </div>
//         {shipmentType==="LCL" && (shipmentScope==="Port-to-Door" || shipmentScope==="Door-to-Door") && <ControlledDetailSwitch control={control} isChecked={isFba} formKey="isFba"  title="FBA" />}
//         </div>
//         <div className="grid grid-cols-2  h-[67px] place-content-stretch">
//             {shipmentScopes.map((scope)=><LabeledRadioButton label={scope} selected={shipmentScope===scope} onClick={()=>{
//                 enquiryStore.current.shipmentScope = scope;
//                 filtersState.filteredList = [];
//                 setValue("origin", "");
//             setValue("destination", "");
//             setValue("cargodetails",[new Cargo()]);
//             setValue("containerdetails",[new Container()]);
//             clearErrors();
//             }} />)}
//         </div>
//         <div className="flex flex-col w-full gap-[4px]">
//         <p className="text-xs font-bold text-[#2C358A]">Select Origin *</p>
//         <AgMuiUn clearErrors={clearErrors} trigger={trigger} validateAddress={true} placeholder="Choose Origin" key={shipmentScope+shipmentType} rules={{ required: "Origin is Required" }} isDoor={shipmentScope === SHIPMENT_SCOPE.D2D || shipmentScope === SHIPMENT_SCOPE.D2P} control={control} name="origin" />
//         </div>
//         <div className="flex flex-col w-full gap-[4px]">
//         <p className="text-xs font-bold text-[#2C358A]">Select Destination *</p>
//         <AgMuiUn clearErrors={clearErrors} trigger={trigger} validateAddress={true} placeholder="Choose Destination" key={shipmentScope+shipmentType} rules={{ required: "Destination is Required" }} isDoor={shipmentScope === SHIPMENT_SCOPE.D2D || shipmentScope === SHIPMENT_SCOPE.P2D} control={control} name="destination" />
//         </div>
//         <div className="grid grid-cols-2 gap-3 h-[67px] place-content-stretch">
//         <LabeledTextField labelStyle={{fontSize:"12px",color:"#2C358A",fontWeight:"bold"}} label="Cargo Readiness Date"  inputProps={{ min: new Date().toISOString().split('T')[0] }} onChange={(val)=>{
//             enquiryStore.current.enqRoute.cargoReadinessDate = val
//         }}  control={control} name="cargoreadinessdate" type="date" size="small" fullWidth />
//         <LabeledTextField labelStyle={{fontSize:"12px",color:"#2C358A",fontWeight:"bold"}} label="Customer Reference Number"  control={control} name="customerreference"  />
//         </div>
//     </div>
//     </div>
// }

function MuiEnquiryConfirmation({open,setClose}){
    return <CustomPopup isOpen={open} onClose={setClose} title="Enquiry Confirmation">
          <Box sx={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"end"}}>
                <Box sx={{display:"flex",flex:1,width:"100%",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:2}}>
                <Typography sx={{fontFamily:"Figtree",color:"black",textAlign:"center"}}>Enquiry# <CopyText text={configStore.enquiryModalNum} /> Submitted Successfully</Typography>
                <Typography sx={{fontFamily:"Figtree",color:"black",textAlign:"center"}}>Thank you for your enquiry. We will share our quotation along with available schedules shortly</Typography>
                <Typography sx={{fontFamily:"Figtree",color:"black",textAlign:"center"}}>We appreciate your patience.</Typography>
                <Typography sx={{fontFamily:"Figtree",color:"black",textAlign:"center"}}>Team Agraga</Typography>
                </Box>
                </Box>
    </CustomPopup>
}

function FilterBox({ title, content }) {
    return <Box sx={{ display: "flex", flexDirection: "column", paddingY: "6px" }}>
            {content}
    </Box>
}



function FilterSwitch({ title, routeKey }) {
    return <span style={{ display: "flex", flexDirection: "row", gap: 8, alignItems: "center" }}>
        {/* <input type="checkbox"  class="w-5 h-5 text-blue-dark bg-gray-100 border-gray-300 rounded-[5px] dark:bg-gray-600 dark:border-gray-500 focus:ring-blue-dark dark:focus:ring-blue-dark"/> */}
        <AntSwitch sx={{padding:0}} onChange={(value)=>{
        let {checked} = value.target
         if(checked){
            filtersState.addFilterOption(["routing",routeKey],title)
         }else{
            filtersState.removeFilterOption(["routing",routeKey],title)
         }

    }} style={{ scale: "0.8" }} />
    <Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "14px" }}>{title}</Typography></span>
}

const filters = [{
    title : "Place of Receipt",content:<FilterBox title="Place of Receipt" content={<>
        {filtersOptions.routing.por.map((title)=><FilterSwitch routeKey="por" title={title} />)}
     </>} />
},
{title: "Port of Discharge",content: <FilterBox title="Port of Discharge" content={<>
    {filtersOptions.routing.pod.map((title)=><FilterSwitch routeKey="pod" title={title} />)}
</>} />},
{
    title :"Final Place of Delivery", content :  <FilterBox title="Final Place of Delivery" content={<>
        {filtersOptions.routing.fpod.map((title)=><FilterSwitch routeKey="fpod" title={title} />)}
    </>} />
}
]

function CustomAccordion({ title, src, content, open, setAccordion,isLast=false }) {

    return <Box sx={{ display: "flex", flexDirection: "column",borderBottom: isLast ? "" : "0.5px solid #D4D4D4",paddingY:"10px",justifyContent:"center"}}>
        <Box onClick={() => {
            setAccordion();
        }} sx={{
            "&:hover": {
                cursor: "pointer"
            }, display: 'flex', flexDirection: "row", justifyContent: "space-between", padding: "4px 8px"
        }}>
             <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                <Typography sx={{color:"black",fontSize:"14px"}}>{title}</Typography>
            </Box>
            <img src={accordionIcon} />
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit={false}><Box sx={{ display:"flex",flex:1,overflowY: "auto", padding: "8px" }}>{content}</Box></Collapse>
    </Box>
}

function Filters(){ 
    const [selectedIndex, setIndex] = useState(-1);
    return <div className="flex flex-col w-[210px] max-h-max">
        <div className="flex flex-col rounded-t-[7px] bg-blue-dark h-[60px] gap-0.5 justify-center pl-4">
            <div className="flex flex-row gap-2">
            <img src={filterIcon}/>
            <p className="text text-white font-bold">Filter</p>
            </div>
            <p className="text-sm text-[#FFFFFF94]">{filtersState.filteredList.length} of {filtersState.routes.length} Results</p>
        </div>
        <div className="flex flex-col bg-[white] rounded-b-[7px] shadow-custom">
        {filters.map((filter, i) =>
            <CustomAccordion key={i} isLast={i===filters.length-1} title={filter.title} src={filter.src} content={filter.content} open={i === selectedIndex} setAccordion={() => {
                if (i === selectedIndex) {
                    setIndex(-1)
                } else {
                    setIndex(i);
                }
            }} />
        )}
        </div>
    </div>
}

export const calcCargoDetails = (cargoDetails,isDimension,dimensionUnit) => {
    const totals = {
        totNumPackages : 0,
        totTotalWeight : 0,
        totTotalVolume : 0,
        totChargeableWeight : 0
    }

    cargoDetails.forEach((e) => {
        let totalWeight = 0;
        let totalVolume = 0;
        let volPerPackage = 0;
        if (isDimension) {
            let l = Utils.toUnit(parseFloat(e.dimensions.length||0),dimensionUnit);
            let w = Utils.toUnit(parseFloat(e.dimensions.width||0),dimensionUnit);
            let h = Utils.toUnit(parseFloat(e.dimensions.height||0),dimensionUnit);
            volPerPackage = (l * w * h) / 1000000.0;
            volPerPackage = parseFloat(volPerPackage.toFixed(5));
            totalWeight = parseFloat(parseFloat(e.quantity||0) * parseFloat(Utils.toUnit(e.weight.value||0,e.weight.unit)));
            totalVolume = (volPerPackage) * parseFloat(e.quantity||0) ;
            if(enquiryStore.current.shipmentType.toLowerCase().includes('air')){
                totalVolume = totalVolume * 166.67;
            }
        }else{
            totalWeight = Utils.toUnit(parseFloat(e.totalWeight.value||0),e.totalWeight.unit)
            totalVolume = Utils.toUnit(parseFloat(e.totalVolume.value||0),e.totalVolume.unit);
        }
console.log(" the totakl volume is ",totalVolume);
        totals.totNumPackages += parseFloat(e.quantity);
        totals.totTotalWeight += parseFloat(totalWeight);
        totals.totTotalVolume += parseFloat(totalVolume.toFixed(3));
        // cargoStore.totals.totChargeableWeight += parseFloat(e.chargeableWeight)
        totals.totTotalWeight = Utils.R(
          totals.totTotalWeight
        );
        totals.totTotalVolume = Utils.R(
          totals.totTotalVolume
        );
        totals.totChargeableWeight =
          Math.max(
            totals.totTotalWeight,
            totals.totTotalVolume
          );
      });
    return totals;
};



export function CreateBooking(){
    useSnapshot(truckLoader);
    useSnapshot(enquiryStore);
    useSnapshot(configStore);
    useSnapshot(breadCrumbStore);
    useSnapshot(filtersOptions);
    useSnapshot(loginStore);
    const [selectedType, setType] = useState( enquiryStore.current.shipmentType);
    const [open,setOpen] = useState(false);
    const [disable,setDisable] = useState(false);
    const [showPopup,setShowPopup] = useState(false);
    const [openEnquiryConfirm,setOpenEnquiryConfirm] = useState(false);
    console.log("the enquiry store ",enquiryStore.current);

    const cargoForm = useForm({
        defaultValues: {
            mode: "LCL",
            origin: enquiryStore.current.enqRoute.origin,
            destination: enquiryStore.current.enqRoute.destination,
            orginScope: false,
            destinationScope: false,
            cargoreadinessdate: new Date().toISOString().split('T')[0],
            scope: enquiryStore.current.shipmentScope,
            shipmentType: enquiryStore.current.shipmentType,
            originType: false,
            destinationType: false,
            customerreference : enquiryStore.current.enqRoute.customerReferenceNum,
            hazardous: enquiryStore.current.hazardous === "No" ? false : true,
            quoteClass: enquiryStore.current.quoteClass,
            packingGroup: enquiryStore.current.packingGroup,
            temperature: enquiryStore.current.temperature,
            cargoValue: enquiryStore.current.cargoValue,
            unnumber: enquiryStore.current.unnumber,
            nonStackable:  enquiryStore.current.nonStackable === "No" ? false : true,
            temperatureControlled:  enquiryStore.current.temperatureControlled === "No" ? false : true,
            minTemperature: enquiryStore.current.minTemperature,
            maxTemperature: enquiryStore.current.maxTemperature,
            stuffingType: enquiryStore.current.stuffingType,
            destuffingType: enquiryStore.current.destuffingType,
            status: "ENQUIRY",
            cargoDimensionUnit: "Cms",
            enqRoute: enquiryStore.current.enqRoute,
            cargoDetails: enquiryStore.current.cargoDetails,
            vehicledetails : enquiryStore.current.vehicles,
            isFba:false,
            showPalletLabel:false,
            ior:false,
            occ : false,
            survey:false,
            storageAtDestination : false,
            days : 0,
            palletizationandlabelling : true,
            showCargoDetailsOutGuage: false,
            multidest:enquiryStore.current.multidest,
            containerDetails:[new Container()],
             fbaalways:"No"
        }
    })
    const { control, watch, register, setValue, handleSubmit, reset, clearErrors, formState, trigger, getFieldState,getValues } = cargoForm;
    const shipmentScope = watch("scope");
    const cargoReadinessDate = watch("cargoreadinessdate");

    useEffect(()=>{
        if(enquiryStore.current.shipmentType.length>3 && enquiryStore.current.shipmentType.toLowerCase().indexOf('fcl')!=-1){
            enquiryStore.current.shipmentType = 'FCL'
            setValue("mode",'FCL');
        }
        console.log(" the multidwst is ",enquiryStore.current.multidest," ",getValues("multidest"));
       
        if(enquiryStore.current.multidest==undefined){
            if(enquiryStore.current.containerDetails==undefined) enquiryStore.current.containerDetails = [new Container()]
            if(enquiryStore.current.cargoDetails==undefined) enquiryStore.current.cargoDetails = [new Cargo()]
            setValue("multidest", [
                {
                    destination:enquiryStore.current.enqRoute.destination,
                    cargoDetails:enquiryStore.current.cargoDetails,
                }
            ]);
            enquiryStore.current.multidest = [
                {
                    destination:enquiryStore.current.enqRoute.destination,
                    cargoDetails:enquiryStore.current.cargoDetails,
                }
            ]
        }else{
            setValue("multidest",enquiryStore.current.multidest);
        }
    },[enquiryStore.current])

    useEffect(()=>{
        enquiryStore.current.enqRoute.cargoReadinessDate = cargoReadinessDate
    },[cargoReadinessDate])
 
    useEffect(()=>{
        setValue("mode",selectedType);
    },[selectedType])

    useEffect(() => {
        enquiryStore.current.countryFromEnquiry = 'India'
    }, []);

    useEffect(() => {
        rtpStore.countryName = enquiryStore.current.countryFromEnquiry
    }, [enquiryStore.current.countryFromEnquiry])
   

    useEffect(()=> {
        console.log("COnfig Store: ", configStore.currentSelectedEntity.entityId.length)
        if(loginStore.isClient() && configStore.currentSelectedEntity.entityId.length === 0){
            rtpStore.customerTBA = "TBA";
        }else{
            rtpStore.customerTBA = "";
        }
        // enquiryStore.current.initialize();
        // enquiryStore.current.shipmentType = 'LCL';
        return ()=>{
            // filtersState.initialize();
        }
    },[loginStore,configStore]);

    useEffect(()=>{
        truckLoader.open = disable;
     },[disable])
     const checkcontainer = (containerDetails)=>{
        let k = {}
        let l = true
        for (let j = 0; j < containerDetails.length; j++) {
            if(containerDetails[j]['containerType']!=undefined && containerDetails[j]['containerType'].length>0){
                let c = containerDetails[j]['containerType']
                if(k[c]==undefined){
                k[c] = Number(containerDetails[j]['wtPerContainer'])
                }
                if(k[c]!=Number(containerDetails[j]['wtPerContainer'])){
                configStore.setModalMessage(
                    `Different Container Weight Not Allowed (${containerDetails[j]['containerType']})`
                )
                l=false
                }
            }
        }
        return l
      }
    
    const onSubmit = async (data, e) => {
        // console.log(" the form data is ",data)
        filtersState.initialize();
        try{
            if(checkcontainer(data.containerDetails)){
            if(configStore?.currentSelectedEntity?.status !== ENTITY_STATUS.DEACTIVATED){
        
                setDisable(true);
                let cargo = []
                let container = []
                let obj = {}
                let multidest_new = []
                for (let i = 0; i < data.multidest.length; i++) {
                    let z1 = {}
                    z1['destination'] = data.multidest[i]['destination']
                    z1['cargoDetails'] = []
                    if(i==0){
                        enquiryStore.current.enqRoute.destination = data.multidest[i]['destination']
                    }else{
                        let k = i+1
                        enquiryStore.current.enqRoute['destination'+k] = data.multidest[i]['destination']
                    }
                    for (let j = 0; j < data.multidest[i]['cargoDetails'].length; j++) {
                        let k = data.multidest[i]['cargoDetails'][j]
                        k = Utils.setCargo_unit(k,data.multidest[0]['cargoDetails'][0],['weight','chargeableWeight','volume','totalWeight','totalVolume','dimensions'],data.cargoDimensionUnit)
                        z1['cargoDetails'].push(k)
                        cargo.push(k)
                    }
                    multidest_new.push(z1)
                }
                
                for (let j = 0; j < data.containerDetails.length; j++) {
                    let z = data.containerDetails[j]['containerType']
                    if(obj[z]==undefined){
                        obj[z] = {
                            containerType : "",
                            numContainers: 0,
                            unit: "",
                            wtPerContainer: 0
                        }
                    }
                    obj[z]['containerType'] = z
                    obj[z]['unit'] = data.containerDetails[0]['unit']
                    obj[z]['numContainers'] = obj[z]['numContainers']+Number(data.containerDetails[j]['numContainers'])
                    obj[z]['wtPerContainer'] = Number(data.containerDetails[j]['wtPerContainer'])
                }
                let o = Object.keys(obj)
                for (let i = 0; i < o.length; i++) {
                    obj[o[i]]['wtPerContainer'] = obj[o[i]]['wtPerContainer']
                    container.push(obj[o[i]])
                }
              
                // enquiryStore.current.enqRoute.cargoReadinessDate = data.cargoreadinessdate
                // enquiryStore.current.enqRoute.destination = data.destination
                enquiryStore.current.multidest = JSON.parse(JSON.stringify(multidest_new));
                enquiryStore.current.enqRoute.origin = data.origin
                enquiryStore.current.shipmentScope = data.scope
                enquiryStore.current.shipmentType = data.mode
                enquiryStore.current.enqRoute.customerReferenceNum = data.customerreference
        
                    let ocountry = 'origin';
                    let dcountry = 'destination';
                    let portre = /.*\((.....)\)$/;
                    let doorre = /.*\[(..,.*)\]$/;
                    let otype = enquiryStore.current.shipmentScope.split("-")[0];
                    let dtype = enquiryStore.current.shipmentScope.split("-")[2];
                    let origin1 = enquiryStore.current.enqRoute.origin;
                    let destination1 = enquiryStore.current.enqRoute.destination;
        
                    if (origin1.includes('(') || origin1.includes('[')) {
                        if (otype == 'Port') ocountry = origin1.match(portre)[1].slice(0, 2)
                        else ocountry = origin1.match(doorre)[1].slice(0, 2)
                    }
        
                    if (destination1.includes('(') || destination1.includes('[')) {
                        if (dtype == 'Port') dcountry = destination1.match(portre)[1].slice(0, 2)
                        else dcountry = destination1.match(doorre)[1].slice(0, 2)
                    }
        
                    if (ocountry == dcountry) {
                        enquiryStore.isForEnquiry = true;
                        // enquiryStore.messageForEnquiry = 'We do not have Domestic Transport Capabilities at present ' + ocountry + '/' + dcountry
                        configStore.setModalMessage('We do not have Domestic Transport Capabilities at present ' + ocountry + '/' + dcountry)
                        setDisable(false)
                        return;
                    }
                    if (ocountry != 'IN' && ocountry != 'origin' && dcountry != 'IN' && dcountry != 'destination') {
                        
                        enquiryStore.isForEnquiry = true;
                        enquiryStore.messageForEnquiry = 'We do not serve these routes at present and will keep you posted when we launch in these markets'
                        // configStore.setModalMessage('We do not serve these routes at present and will keep you posted when we launch in these markets')
                        // enquiryStore.current.enqRoute.origin = ''
                        // enquiryStore.current.enqRoute.destination = ''
                        setDisable(false)
                    }
                    enquiryStore.vehicleDetailsNeeded = false;
                    enquiryStore.isForEnquiry = false;
                    //     if(enquiryStore.current.shipmentScope.includes("Door")){
                    //     let r = /\[.*,([^,]*)\]/
                    //     let route = enquiryStore.current.enqRoute
                    //     let url;
                    //     // let locType;
                    //     if (route.origin.includes('[IN')) {
                    //         locType.current = 'Pick-up'
                    //         let arr = route.origin.match(r)
                    //         let pin = arr[1]
                    //         url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getvehicles4pin/pickup/${pin}`
                    //     } else if (route.destination.includes('[IN')) {
                    //         locType.current = 'Delivery'
                    //         let arr = route.destination.match(r)
                    //         let pin = arr[1]
                    //         url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getvehicles4pin/delivery/${pin}`
                    //     }
                    // //     console.log("the url is ",url);
                    //   if(url){  
                    //     let ret = MonApi.aget(url).then(data => {
                    //         if (locType && (!data || !data.length)) {
                    //             enquiryStore.isForEnquiry = true;
                    //             enquiryStore.messageForEnquiry = `Unfortunately, we do not have transport rates for this ${locType.current} Address. We are still expanding our Geographical presence and will update you once the facility gets activated. Meantime, please feel free to place an Enquiry with us and we will ensure a quotation is provided at the earliest.`
                    //             // configStore.setModalMessage(`Unfortunately, we do not have transport rates for this ${locType} Address. We are still expanding our Geographical presence and will update you once the facility gets activated. Meantime, please feel free to place an Enquiry with us and we will ensure a quotation is provided at the earliest.`, [], false)
                    //             setDisable(false)
                    //         } else {
                    //             // setVehicles(data)
                    //             enquiryStore.current.vehicleDetails.fieldkeys = data;
                    //         if(data && data.length>0){
                    //             data.forEach((vehicle)=>{
                    //                 enquiryStore.current.vehicles.push(new Vehicle(vehicle))
                    //             })
                    //         }
                    //             setDisable(false)
                    //             enquiryStore.vehicleDetailsNeeded = true;
                    //             console.log("the data are ",data);
                    //         }
                    //         breadCrumbStore.setBreadCrumbs(["Dashboard","Cargo Details"])
                    //     })}else{
                    //         breadCrumbStore.setBreadCrumbs(["Dashboard","Cargo Details"])
                    //     }
                    // }else{
                        // breadCrumbStore.setBreadCrumbs(["Dashboard", "Cargo Details"])
                    // }
                    let cargoDetails;
                    // if(enquiryStore.current.shipmentType.toLowerCase()==="lcl" && enquiryStore.current.shipmentScope==="Port-to-Port" && data.cargoDetails[0].totalWeight.value==0){
                    //     cargoDetails = CargoHelper.convertCargoDetails2stdUnits(cargo,false);
                    // }else{
                        cargoDetails = cargo
                    // }
                    enquiryStore.current.containerDetails = JSON.parse(JSON.stringify(container))
                    enquiryStore.current.cargoDetails = JSON.parse(JSON.stringify(cargo));
                    enquiryStore.current.hazardous = data.hazardous ? "Yes" : "No";
                    enquiryStore.current.nonStackable = data.nonStackable ? "Yes" : "No";
                    enquiryStore.current.cargoDimensionUnit = data.cargoDimensionUnit;
                    enquiryStore.current.unnumber = data.unnumber;
                    enquiryStore.current.packingGroup = data.packingGroup
                    enquiryStore.current.quoteClass = data.quoteClass
                    enquiryStore.current.stuffingType = data.stuffingType
                    enquiryStore.current.destuffingType = data.destuffingType
                    enquiryStore.current.minTemperature = data.minTemperature
                    enquiryStore.current.maxTemperature = data.maxTemperature
                    enquiryStore.current.temperature = data.temperature
                    enquiryStore.current.cargoValue = data.cargoValue;
                    enquiryStore.current.fba = data.isFba ? "Yes" : "No";
                    enquiryStore.current.fbaIorRequired = data.ior ? "Yes" : "No";
                    enquiryStore.current.fbaOCC = data.occ ? "Yes" : "No";
                    enquiryStore.current.fbaDCC = data.dcc ? "Yes" : "No";
                    enquiryStore.current.fbaPAL = data.palletizationandlabelling ? "Yes" : "No";
                    enquiryStore.current.fbaSAD = data.storageAtDestination ? "Yes" : "No";
                    enquiryStore.current.fbaSurvey = data.survey ? "Yes" : "No";
                    enquiryStore.current.fbaDays = data.days!=undefined? data.days: 0 ;
                    // loader.show();
                    let pricingStore = {
                        gst: '',
                        origin: '',
                        destination: ''
                    }
                    let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getroutes`
                    if(enquiryStore.current.shipmentType.toUpperCase()==="AIR") url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/airpricing/getroutes`
                    if (enquiryStore.current.shipmentType.toUpperCase() == 'FCL') url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/getroutesv2`
                    if (loginStore.isClient()) pricingStore.gst = configStore.currentSelectedEntity.gst;
                    enquiryStore.current.gst = pricingStore.gst;
                    enquiryStore.current.entity = pricingStore.gst;
                    enquiryStore.current.createdBy = loginStore.userRec.email;
                    enquiryStore.current.entityId = configStore.currentSelectedEntity.entityId;
                    let curDate = Utils.getCurrentDateString();
                    let crd = '' + enquiryStore.current.enqRoute?.cargoReadinessDate
                    crd = crd.trim();
                    let displayCargo4fcl = false;
                    if (enquiryStore.current.shipmentType.toUpperCase() == 'FCL') {
                        if (otype != 'Port' && enquiryStore.current.stuffingType != 'Factory') displayCargo4fcl = true
                        if (otype != 'Port' && enquiryStore.current.destuffingType != 'Factory') displayCargo4fcl = true
                    }
                    if (crd == '') {
                        crd = data.cargoreadinessdate
                        enquiryStore.current.enqRoute.cargoReadinessDate = data.cargoreadinessdate
                    }
            
                    if ( rtpStore.customerTBA === "TBA") {
                        enquiryStore.current.checkAgCustomer = 'No'
                        enquiryStore.current.entityName = "YET TO BE ASSIGNED"
                        enquiryStore.current.entityId = "TBA"
                        enquiryStore.current.gst = 'GSTTBA000000000'
                        enquiryStore.current.state = ''
                        enquiryStore.current.createdBy = loginStore.email
                        enquiryStore.current.isfromAgragaEnquiry = 'Yes'
                    }
                    let payload = {
                        entity: enquiryStore.current.entityId,
                        origin: enquiryStore.current.enqRoute.origin,
                        destination: enquiryStore.current.enqRoute.destination,
                        sscope: `${otype}-to-${dtype}`,
                        crd: enquiryStore.current.enqRoute.cargoReadinessDate,
                        curDate,
                        cargoDetails: cargoDetails,
                        vehicleDetails: enquiryStore.current.vehicleDetails,
                        enquiry: enquiryStore.current,
                        fclCargoApplicable:displayCargo4fcl
                    }

                    for (let i = 1; i < multidest_new.length; i++) {
                        if(i!=0){
                            let k = i+1
                            payload['destination'+k] = multidest_new[i]['destination']
                        }
                    }
        
                    if( rtpStore.customerTBA === "TBA"){
                        payload.entity = 'COUNTRY:' + rtpStore.countryName
                    }

                    if(enquiryStore.isForEnquiry){
                        setShowPopup(true);
                        return;
                    }
        
                    if (!PricingUtils.getTotalVehicleCount(payload.vehicleDetails) && enquiryStore.vehicleDetailsNeeded) {
                        configStore.setModalMessage('At least one vehicle needs to be selected for India transport')
                        setDisable(false)
                        setShowPopup(true)
                        return
                    }
                    // if(mode.toLowerCase()==="air"){
        
                    //     saveEnquiry();
        
                    //     return;
                    // }
                    setDisable(true)
                    let ret;
                    if (enquiryStore.current.hazardous == 'Yes' || enquiryStore.current.nonStackable == 'Yes') {
                        ret = []
                    } else {
                      
                    ret = await MonApi.apost(url, payload);
                    if(enquiryStore.current.shipmentType.toLowerCase().includes("fcl")){
                        ret = ret.finalset
                    }
                    }
                   
                    // loader.hide();
                    if (ret?.error) {
                        if(ret?.error==="Vehicles not available for PIN"){
                            enquiryStore.messageForEnquiry = "We do not have these routes real-time but, would love to provide a customized solution. We will get in touch with your shortly over Phone or E-mail.";
                         setShowPopup(true);
                         setDisable(false)
                         return;
                        }
                        configStore.setModalMessage('' + ret.error, [], true, [], '', 5000)
                        setDisable(false)
                        return;
                    } else if(!ret || !ret.length) {
                         setOpen(true);
                         setDisable(false)
                         enquiryStore.messageForEnquiry = "We do not have these routes real-time but, would love to provide a customized solution. We will get in touch with your shortly over Phone or E-mail.";
                         setShowPopup(true);
                         return;
                    }
                       filtersState.setRoutes(ret);
                        
                        setDisable(false)
                }else {
                    configStore.setModalMessage('Your Current Selected Entity is Deactivated')
                }
            }
        }catch(e){
            console.log("the rror is ",e)
          setDisable(false);
            configStore.setModalMessage("Something went wrong ")
        }     
            }

            const saveEnquiry = () => {
                EntityV2Api.viewEntity(configStore.currentSelectedEntity.entityId, (data) => {
                    if (data.status === ENTITY_STATUS.DEACTIVATED) {
                        configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Procced to booking `);
                        setDisable(false);
                        return;
                    }
                    
                    EnquiriesApi.saveEnquiry(() => {
                        
                        setDisable(false);
                        enquiryStore.current.shipmentType = "LCL"
                        setOpenEnquiryConfirm(true);
                        // breadCrumbStore.setCurrentCrumb(0);
                    });
                })               
            }

    return <div className="w-full flex flex-col gap-[8px]">
       
        <div className="w-full h-[40px] bg-blue-dark rounded-t-md flex flex-row">
            {shipmentTYpes.map((type,i)=><div onClick={()=>{
                enquiryStore.initialize();
                filtersState.filteredList = [];
                filtersState.initialize();
                enquiryStore.current.shipmentType = type;
                enquiryStore.current.shipmentScope = "Port-to-Port";
                setValue("origin", "");
                setValue("destination", "");
                setValue("cargoDetails",[new Cargo()]);
                setValue("containerDetails",[new Container()]);
                clearErrors();
                setType(type);
                setValue('showCargoDetailsOutGuage',false);
            }} className={` h-full text-white font-bold text-[18px] flex items-center justify-center w-[80px] uppercase hover:cursor-pointer ${type==="LCL" && "rounded-tl-md"} ${type===selectedType && "bg-yellow"}`}>{type}</div>)}
        </div>
        <form noValidate onSubmit={handleSubmit(onSubmit)} style={{width:"100%",display:"flex",flexDirection:"column"}}>
        <div className="flex flex-row gap-[10px]">
           
        <Route form={cargoForm} enableCrn={true}/>
       <Details form={cargoForm}/> 
     
        </div>
        <div className="flex flex-row w-full items-center justify-end h-[76px] border-b-solid border-b-2 border-b-[#D4D4D4]">
        <button disabled={disable} type="submit" className="flex items-center justify-center text-white bg-blue-dark rounded-[40px] w-[104px] h-[40px]">Search</button>
       </div>
        </form>
        {filtersState.filteredList?.length>0 && <div className="flex flex-row gap-2.5 relative pt-8">
            {/* <Filters/> */}
            <div className="flex grow">
            <ViewSchedule isSpot={true} chooseSchedule={true} />
            </div>
            </div>}
        { truckLoader.open && <TruckLoader/>}

        {openEnquiryConfirm && <MuiEnquiryConfirmation open={openEnquiryConfirm} setClose={()=>{
            setOpenEnquiryConfirm(false);
            reset();
            setValue("mode","LCL");
            setValue("cargoDetails",[new Cargo()]);
            setValue("containerDetails",[new Container()]);
            setType('LCL')
            enquiryStore.initialize();  
        }} />}
      { showPopup &&  <CustomPopup isOpen={showPopup} onClose={()=>{
          setShowPopup(false);
      }} >
        <Box sx={{width:"400px",height:"100%",display:"flex",flexDirection:"column",alignItems:"end",gap:2}}>
                <Box sx={{display:"flex",flex:1,width:"100%",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:2}}>
               
                    <Typography sx={{fontFamily:"Figtree",fontWeight:"bold"}}>{enquiryStore.messageForEnquiry}</Typography>
                </Box>
                <Box sx={{display:"flex",flexDirection:"row",justifyContent:"end",gap:1,width:"100%"}}>
                { !(loginStore.isClient() && configStore.currentSelectedEntity.entityId.length === 0 ) ?
                <>
                <Button onClick={()=>{
                    //  if(locType.current === "Pick-up"){
                    //     setValue("origin", "");
                    //  }else{
                    //     setValue("destination", "");
                    //  }
                     setShowPopup(false)
                  }} sx={{textTransform:"capitalize",backgroundColor:"#F5F5F5",color:"#2C358A",borderRadius:"5px","&:hover":{
                    backgroundColor:"#F5F5F5",color:"#2C358A"
                  }}}>Change Address</Button>
                    <Button onClick={()=>{
                        setShowPopup(false);
                        const uniqueContainerType = [];
                        const duplicateContainerType = [];
                        // data.containerdetails.forEach((obj) => {
                        //     const value = obj['containerType'];
                        //     if (uniqueContainerType.includes(value)) {
                        //         duplicateContainerType.push(value);
                        //     } else {
                        //         uniqueContainerType.push(value);
                        //     }
                        // });
                        enquiryStore.current.isfromAgragaEnquiry = "Yes";
                        if(duplicateContainerType.length>0) {
                            return configStore.setModalMessage(`Same ContainerType Is Not Allowed.`)
                        }
                
                        let scope = enquiryStore.current.shipmentScope
                        if(enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()==="lcl" ){
                            let totalWeight = 0;
                            let totalVolume = 0;
                            for(let i=0;i<enquiryStore.current.cargoDetails.length;i++){
                                let cargo =  enquiryStore.current.cargoDetails[i];
                                if( scope === 'Port-to-Port'){
                                    totalWeight += parseFloat(cargo?.totalWeight?.value || 0.0);
                                    totalVolume += parseFloat(cargo?.totalVolume?.value || 0.0)
                                }else{
                                    totalWeight += parseFloat(cargo?.weight?.value || 0.0);
                                }
                            }
                            let weightUnit ;
                            if(scope === "Port-to-Port"){
                                weightUnit = enquiryStore.current.cargoDetails[0].totalWeight.unit;
                            }else{
                                weightUnit =  enquiryStore.current.cargoDetails[0].weight.unit;
                            }
                            let volumeUnit = enquiryStore.current.cargoDetails[0].totalVolume.unit;
                            let maxWeight = lclWeightForPort["totalweight"][weightUnit.toLowerCase()];
                            let maxVolume = lclWeightForPort["totalvolume"][volumeUnit.toLowerCase()]
                            if(totalWeight>maxWeight){
                                configStore.setModalMessage(`Total weight per shipment can't be greater than ${maxWeight} ${weightUnit}`)
                                return;
                            }else if( scope === "Port-to-Port" && totalVolume>maxVolume){
                                configStore.setModalMessage(`Total volume per shipment can't be greater than ${maxVolume} ${volumeUnit}`)
                                return;
                            }
                        }
                        setDisable(true)
                saveEnquiry();
                    }} sx={{textTransform:"capitalize", backgroundColor:"#2C358A",color:"#F5F5F5",borderRadius:"5px","&:hover":{
                    backgroundColor:"#2C358A",color:"#F5F5F5"
                  }}}>Enquiry</Button>
                  </> : <Button sx={{textTransform:"capitalize", backgroundColor:"#2C358A",color:"#F5F5F5",borderRadius:"5px","&:hover":{
                    backgroundColor:"#2C358A",color:"#F5F5F5"
                  }}} onClick={()=>{
                    setShowPopup(false);
                  }}>OK</Button>
                  }
                  </Box> 
            </Box>
        </CustomPopup>}
        {/* <ShippingDetailsV2/> */}
    </div>
}