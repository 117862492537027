import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { EntityV2Store, bookingsStore,configStore,loginStore } from "../state/CommonState";
import Api from "../state/Api"

let server = process.env.REACT_APP_FILE_SERVER;

export function AgSegmentCard({ type, userList, dataList }) {
  const [contactList, setContactList] = useState([]);
  const [contactFlag, setContactFlag] = useState(false);
  const bookingData = bookingsStore.current;
  useSnapshot(EntityV2Store);
  const getUpdatedType = (label) =>
    label
      .split(' ')
      .map((word, index) => index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join('');


  const updatedType = getUpdatedType(type)

  const Entity = EntityV2Store.EntityRec;

  useEffect(() => {
    if (!bookingData?.vendor) {
      bookingData.vendor = {};
    }
    if (!bookingData.vendor[updatedType]) {
      bookingData.vendor[updatedType] = {
        vendorId: "",
        doc: {
          filename: "",
           pkey: ""
        },
        assignUser: "",
        remarks: "",
      };
    }

  }, [bookingData, updatedType]);


  useEffect(() => {

    if (bookingsStore?.current?.vendor && (Object.keys(bookingsStore?.current?.vendor).length > 0) && bookingsStore.current?.vendor?.[updatedType]?.vendorId) {
      Api.fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/${bookingsStore.current?.vendor?.[updatedType].vendorId}`,
        (data1) => {
          if (data1?.logisticInformation) {
            setContactList(data1?.logisticInformation)
          }

        })
    }

  }, [])

  if (!bookingData?.vendor?.[updatedType]) {
    return <div>Loading...</div>;
  }

  function change(files, filetype, i) {
    const formData = new FormData();
    formData.append("myFile", files[0]);
    formData.append("label", filetype);
    formData.append("key", i);
    fetch(`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/storefile`, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) configStore.setModalMessage(data.error);
        else{
          bookingData.vendor[updatedType].doc = {
            filename: data.key.filename,
            pkey: data.key.pkey
           };
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }


  const handleVendorChange = (e) => {
    bookingData.vendor[updatedType].vendorId = e.target.value;
    bookingData.vendor[updatedType].assignUser = ""
    bookingData.vendor[updatedType].doc={
        filename: "",
        pkey: ""
    }
    bookingData.vendor[updatedType].updatedAt = Date.now()
    bookingData.vendor[updatedType].updatedBy = loginStore.email
    setContactFlag(true)
    if (e.target.value !== "") {
      Api.fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/${e.target.value}`,
        (data1) => {
          if (data1?.logisticInformation?.length>0 && validateContactList(data1?.logisticInformation)) {
            setContactList(data1?.logisticInformation)

          } else {
            setContactList([])
            bookingData.vendor[updatedType].vendorId = ""
          }

        })
    }

  };

  const handleUserChange = (e) => {
    bookingData.vendor[updatedType].assignUser = e.target.value;
  };

  const handleRemarksChange = (e) => {
    const value = e.target.value;
    if (value.length <= 100) {
      bookingData.vendor[updatedType].remarks = value;
    }
  };

  const handleFileUpload = (data) => {
    bookingData.vendor[updatedType].doc = {
      filename: data.key.filename,
      pkey: data.key.pkey
     };
  };

  const handleDeleteFile = () => {
    bookingData.vendor[updatedType].doc = {  
      filename: "",
      pkey: ""};
  };

  const isValidContact = (contact) => {
    return contact.customercontact && contact.department && contact.contactnum && contact.email;
 };
 
 const validateContactList = (contactList) => {
   const validContacts = contactList.filter((r) => isValidContact(r));
   if (validContacts.length === 0) {
     return false;
   }
    return validContacts;
 };



  return (
    <div style={{ marginBottom: "16px" }}>
      <h6 className="title is-6" style={{ fontFamily: "product_sans_bold" }}>
        {type}
      </h6>
      <div className="p-4 shadow-custom" style={{ width: "100%", boxSizing: "border-box" }}>
        <>
          <div className="w-full">
            <div className="flex justify-between items-center w-full pt-4">
              <div className="field pl-1">
                <div className="control">
                  <div className="select is-small" style={{ width: "250px" }}>
                    <select
                      name="vendor"
                      value={bookingData.vendor[updatedType]?.vendorId || ""}
                      onChange={handleVendorChange}
                      className="w-full"
                    >
                      <option value="">
                        Select Vendor
                      </option>
                      {Array.isArray(dataList[type]) && dataList[type].map((val) => (
                        <option key={val.entityID} value={val.entityID}>
                          {val.entityName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {bookingData.vendor[updatedType].vendorId === "" &&
                <div className="field pr-2">
                  <label
                    htmlFor="user"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Assign User
                  </label>
                  <div className="control">
                    <div className="select is-small" style={{ width: "275px" }}>
                      <select
                        id="user"
                        name="user"
                        value={bookingData.vendor[updatedType].assignUser || ""}
                        onChange={handleUserChange}
                        className="w-full"
                      >
                        <option value="">
                          Select User
                        </option>
                        {userList.length > 0 && userList.map((val) => (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>}
            </div>

            <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem", marginTop: '1rem' }}>
              <label
                style={{
                  display: "block",
                  fontSize: "0.9rem",
                  marginBottom: "0.5rem",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                  marginTop: "1rem",
                }}
              >
                {"Upload supporting documents for cost"}
              </label>
              {!bookingData.vendor[updatedType].doc.filename && (
                <input type="file" disabled={false} style={{fontSize: '0.8rem'}}
                onChange={(e1) => {
                  change(e1.target.files,updatedType,bookingData.entityId+"_"+bookingData._id+"_"+updatedType)
                }}
                />
              )}
              {bookingData.vendor[updatedType].doc.filename && (
                <div>
                  <a target="_blank" href={server + '/api/v1/filestore/getfile/' +bookingData.vendor[updatedType].doc.pkey+"/"+bookingData.vendor[updatedType].doc.filename} rel="noreferrer">
                    {bookingData.vendor[updatedType].doc.filename}
                  </a>
                  &nbsp;
                  <button
                    className="button is-small is-danger"
                    onClick={handleDeleteFile}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              )}
            </div>

            {/* Remarks Input */}
            <input
              type="text"
              className="input is-small"
              name="specialRemarks"
              placeholder="Enter Remarks (if any)"
              value={bookingData.vendor[updatedType].remarks}
              onChange={handleRemarksChange}
              style={{
                marginRight: "10px",
                width: "250px",
                marginLeft: "0.5rem",
                marginTop: "0.5rem",
              }}
            />

            {contactList?.length > 0  && validateContactList(contactList)?
              <div className="px-4 mt-2">
                <div className="is-flex pt-4">
                  <table
                    className="table_caf_fin"
                    style={{
                      width: "50%",
                      margin: "10px auto",
                      borderCollapse: "collapse",
                      fontSize: "14px",
                      marginLeft: "-10px",
                      tableLayout: "fixed",
                      border: "1px solid #ccc",
                    }}
                  >
                    <thead style={{ backgroundColor: "#f5f5f5" }}>
                      <tr>
                        <th style={{ padding: "6px", border: "1px solid #ccc" }}>Contact Person</th>
                        <th style={{ padding: "6px", border: "1px solid #ccc" }}>Description/Department</th>
                        <th style={{ padding: "6px", border: "1px solid #ccc" }}>Contact Number</th>
                        <th style={{ padding: "6px", border: "1px solid #ccc" }}>Email</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white" }}>
                      {contactList?.map((r, i) => (
                        <tr key={i} style={{ backgroundColor: "white" }}>
                          <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                            <input
                              className="inp_table_caf"
                              value={r.customercontact}
                              disabled
                              style={{
                                fontSize: "12px",
                                width: "100%",
                                border: "none",
                                backgroundColor: "white",
                              }}
                            />
                          </td>
                          <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                            <input
                              className="inp_table_caf"
                              value={r.department}
                              disabled
                              style={{
                                fontSize: "12px",
                                width: "100%",
                                border: "none",
                                backgroundColor: "white",
                              }}
                            />
                          </td>
                          <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                            <input
                              type="number"
                              className="inp_table_caf"
                              value={r.contactnum}
                              disabled
                              style={{
                                fontSize: "12px",
                                width: "100%",
                                border: "none",
                                backgroundColor: "white",
                              }}
                            />
                          </td>
                          <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                            <input
                              className="inp_table_caf"
                              value={r.email}
                              disabled
                              style={{
                                fontSize: "12px",
                                width: "100%",
                                border: "none",
                                backgroundColor: "white",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              : contactFlag === true && <div
                style={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Entity does not have any contact information. Please add contact details in Entity to assign vendor.
              </div>}
          </div>
        </>
        
      </div>
    </div>
  );
}
