import { useSnapshot } from "valtio";
import { Agyesno } from "../components/Agyesno";
import { configStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { OneLabel } from "./act-common";
import { green } from "@mui/material/colors";
import { useState } from "react";
let server = process.env.REACT_APP_FILE_SERVER

export function Taskfiledisplay1(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    let docs = task.files
    if (!docs) docs = []
    console.log('*************', task)
    const displayApproved = (status) => {
        if (status.toLowerCase() === 'Approve') return 'Approved'
        if (status.toLowerCase() === 'Reject') return 'Rejected'
        return ''
    }
    return (
        <div>
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    <th>Document</th>
                    <th>Link</th>
                    <th></th>
                </tr>
                {

                    (stage==12)?<tr style={{ padding: '0.5rem' }}>
                    <td>{docs[0].label}</td>
                    <td >
                        {
                            (docs[0].filename) ?
                                <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + docs[0].filename}>
                                    {docs[0].filename}
                                </a> : <></>
                        }
                    </td>
                    <td>
                        {displayApproved(docs[0].approved)}
                    </td>
                    <td >
                        {
                            (docs[0].approved === 'Reject') ?
                            docs[0].rejectReason : ''
                        }
                    </td>
                </tr>:<></>
}{


                (stage==13)?<>
                {/* <tr style={{ padding: '0.5rem' }}>
                <td>{docs[1].label}</td>
                <td >
                    {
                        (docs[1].filename) ?
                            <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + docs[1].filename}>
                                {docs[1].filename}
                            </a> : <></>
                    }
                </td>
                <td>
                    {displayApproved(docs[1].approved)}
                </td>
                <td >
                    {
                        (docs[1].approved === 'Reject') ?
                        docs[1].rejectReason : ''
                    }
                </td>
            </tr> */}
            <tr style={{ padding: '0.5rem' }}>
                    <td>{docs[0].label}</td>
                    <td >
                        {
                            (docs[0].filename) ?
                                <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + docs[0].filename}>
                                    {docs[0].filename}
                                </a> : <></>
                        }
                    </td>
                    <td>
                        {displayApproved(docs[0].approved)}
                    </td>
                    <td >
                        {
                            (docs[0].approved === 'Reject') ?
                            docs[0].rejectReason : ''
                        }
                    </td>
                </tr></>:<></>
                }
            </table>
            <hr />
        </div>
    )
}

export function Taskfiledisplaynew(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    let docs = task.files
    const [show, setShow] = useState(false)
    if (!docs) docs = []
    const check = () =>{
        let l = false
        docs.map((e, i) =>{
            if(e.filename.trim().length >0){
                l = true
            }
        })
        if(l!=show){
            setShow(l)
        }
    }
    check()
    return (
        <>
        {
            (show)?<div>
                <hr />
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    <th>Document</th>
                    <th>Link</th>
                    

                </tr>
                {
                    docs.map(e => <tr style={{ padding: '0.5rem' }}>
                        <td>{e.label}</td>
                        <td >
                            {
                                (e.filename) ?
                                    <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + e.filename}>
                                        {e.filename}
                                    </a> : <></>
                            }
                        </td>
                       

                    </tr>)
                }
              

            </table>
       
          
            <hr />
        </div>:""
        }
        
        </>
    )
}

export default function Taskfiledisplay(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage
    let docs = task.files
    const [approved, setApproved] = useState(false)
    const [status, setStatus] = useState(true)
    if (!docs) docs = []
    const check = () =>{
        let l = true
        let l1 = false
        docs.map((e, i) =>{
            if(e.approved != 'Approve'){
                l = false
            }
            if(e.approved && e.approved.trim().length>0){
                l1=true
            }
        })
        if(l!=approved){
            setApproved(l)
        }
        if(l1!=status){
            setStatus(l1)
        }
    }
    check()
    console.log('*************', task)
    const displayApproved = (status,approvedat) => {
        if (status.toLowerCase() === 'approve') return `Approved on ${approvedat}`
        if (status.toLowerCase() === 'reject') return `Rejected on ${approvedat}`
        return ''
    }
    return (
        <div>
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    <th>Document</th>
                    {props?.additionalField?<th>Document No</th>:<></>}
                    {props?.additionalField?<th>Document Date</th>:<></>}
                    <th>Link</th>
                    {status?<th>Status</th>:""}
                    

                </tr>
                {
                    docs.map(e => <tr style={{ padding: '0.5rem' }}>
                        <td>{e.label}</td>
                        {props?.additionalField?<td>{e.invoiceNo}</td>:<></>}
                        {props?.additionalField?<td>{e.invoiceDate}</td>:<></>}
                        <td >
                            {
                                (e.filename) ?
                                    <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + e.filename}>
                                        {e.filename}
                                    </a> : <></>
                            }
                        </td>
                        <td>
                            {displayApproved(e.approved,e.approvedat)}
                        </td>
                        <td >
                            {
                                (e.approved === 'Reject') ?
                                    e.rejectReason : ''
                            }
                        </td>
                       

                    </tr>)
                }
              

            </table>
       
          
            <hr />
            {approved && task.customerStatus=="COMPLETED" && (task.actionName == "Invoice Acceptance & Payment Details (COD)"||task.actionName === "Payment Details")?  <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                        <tr>
                            <td style={{color:"green"}}>Your Payment is Successful.</td>
                        </tr>
                        <tr>
                            <OneLabel label='NEFT/ RTGS Transaction Reference #'
                                record={task.currentPayment} name='transNum' />
                                <h1></h1>
                        </tr>
                        <tr>
                            <OneLabel type='number' label='Transaction Amount'
                                record={task.currentPayment} name='transAmount' />
                        </tr>
                        <tr>
                            <OneLabel type='date' label='Transaction Date'
                                record={task.currentPayment} name='transDate' />
                                <h1></h1>
                        </tr>
                       
                       
                    </table>:null}
        </div>
    )
}
