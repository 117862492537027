import { useEffect, useState } from "react"
import { useSnapshot } from "valtio"
import { AgSelect } from "../components/AgComponents"
import AgFile2 from "../components/agfile2"
import { configStore } from "../state/CommonState"
import { myactionsstore } from "../my-actions/store-myactions"
import MonApi from "../state/mongo-api"
import Utils from "../util/Utils"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS, VERTICALS } from "../util/constants"

let chargeHeadingsMap = {
    'fcl_origin_charges.xlsx': 'Origin Charges',
    'fcl_oih_charges.xlsx': 'Origin Inland Haulage',
    'fcl_ocean_freight.csv': 'Ocean Freight',
    'fcl_dih_charges.xlsx': 'Destination Inland Haulage',
    'fcl_destination_charges.xlsx': 'Destination Charges',
    'fcl_security_export.csv': 'Security Filing Export',
    'fcl_security_import.csv': 'Security Filing Import',
    'fcl_origin_customs.csv': 'Origin Customs Clearance',
    'fcl_destination_customs.csv': 'Destination Customs Clearance',
    'fcl_transport.csv': 'Transport Charges',
    'fcl_pinunloc.csv': 'PIN/UNLOC mapping',
}
export default function FCLPricingUploads() {
    const [data, setData] = useState({})
    const [chargeType, setChargeType] = useState('')
    const [charges, setCharges] = useState([])
    const [chargesVisible, setChargesVisible] = useState(false)
    const setPricing = (data) => {
        configStore.setModalMessage('Successfully updated details')
        setData(data)
    }
    const getRows = (headings, recs) => {
        let rows = []
        for (let i = 0; i < recs.length; i++) {
            let rec = recs[i]
            let o = []
            for (let j = 0; j < headings.length; j++) {
                let k = headings[j]
                o.push(rec[k])
            }
            rows.push(o)
        }
        return rows
    }
    const getCharges = async (ptype) => {
        setChargeType(chargeHeadingsMap[ptype])
        let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/getcharges/${ptype}`
        let ret = await MonApi.aget(url)
        console.log(ret)
        setCharges(ret)
        setChargesVisible(true)
    }
    useEffect(() => {
        if (charges && charges.length) {
            let headings = Object.keys(charges[0])
            console.log('headings=', headings)
            let ret1 = getRows(headings, charges)
            console.log(ret1)
        }
    }, [charges])
    let errors = data.validationErrors
    const ChargeRow = (props) => {
        const { cid, ptype, label, csvname } = props
        let url = '/api/v1/fclpricing/uploadcharges'
        return (
            <tr id={cid}>
                <td>
                    <button className="button"
                        onClick={(e) => {
                            getCharges(ptype.split('.')[0])
                        }}>
                        <span className="icon ">
                            <i className="fas fa-eye"></i>
                        </span>
                    </button>
                </td>
                <td>
                    <a className="button is-link is-inverted" href={`./${csvname}`} download={csvname}>
                        <span className="icon ">
                            <i className="fas fa-download"></i>
                        </span>
                    </a>
                </td>
                <td>
                    <div className="title is-6">{label}</div>
                </td>
                {
                     RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                     <td>
                     <AgFile2 callback={setPricing} form_data={{ chargeType: ptype.split('.')[0] }} parent={cid}
                         url={url} filetypes='.csv' />
                    </td>
                }
            </tr>
        )
    }
    if (chargesVisible) {
        let headings = []
        if (charges && charges.length) headings = Object.keys(charges[0])
        return (
            <div className="box">
                <div className="columns">
                    <div className="column is-10"><div className="title is-5">{chargeType}</div></div>
                    <div className="column is-2">
                        <button className="button islink"
                            onClick={(e) => {
                                setChargesVisible(false)
                            }}>
                            <span className="icon ">
                                <i className="fas fa-xmark"></i>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="table is-bordered is-striped is-fullwidth is-hoverable ">
                    <tr>
                        {
                            headings.map(e => <th>{e}</th>)
                        }
                    </tr>
                    {
                        getRows(headings, charges).map(e => <tr>
                            {
                                e.map(e1 => <td>{e1}</td>)
                            }
                        </tr>)
                    }
                </div>
            </div>
        )
    }
    let keys = Object.keys(chargeHeadingsMap)
    return (
        <div>
            <div className="columns" style={{ zoom: '0.9' }}>
                <div className="column is-9">
                    <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                        <tbody>
                            {
                                keys.map(e => {
                                    return <ChargeRow cid='td1' label={chargeHeadingsMap[e]}
                                csvname={e} ptype={e} />
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {
                (data.isError) ?
                    <div>
                        <h4 className="title is-6">Errors in DSR data</h4>
                        <table className="table">
                            <tbody>
                                {
                                    Object.keys(errors).map(e => {
                                        return (
                                            <tr>
                                                <td>{e}</td>
                                                <td>{errors[e]}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    : <><h4 className="title is-6">
                        {
                            (Object.keys(data).length > 0) ? "Data uploaded successfully!" : ""
                        }
                    </h4></>
            }
        </div>
    )
}