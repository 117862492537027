import { useEffect, useLayoutEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { subscribe, useSnapshot } from "valtio"
import Api from "../state/Api"
import { AgmodalStore, bookingsStore, configStore, contractsStore, entityStore, loginStore } from "../state/CommonState"
import { Cargo, Container, enquiryStore } from "../enquiry/enquiry-data"
import EnquiriesApi from "../state/enq-api"
import Utils from "../util/Utils"
import { CarrierWebView2v3 } from "./carrierweb-view-v3"
import { useGlobalState } from "../GlobalState"
import { AgInput } from "../components/AgComponents"
import MonApi from "../state/mongo-api"
import TaskTrigger from "../mytasks/task-trigger"
import VendorAssignment from "../mytasks/vendor-assignment-form"
import { taskStore } from "../mytasks/task-store"
import { Task } from "../mytasks/tasks-api"
import Newapi from "../state/new-api"
import { myactionsstore } from "../my-actions/store-myactions"
import { Visibility } from "../my-actions/visibility"
import { VisibilityGraph } from "../my-actions/visibilitygraph"
import BookingSchedule from "../pricing/booking.schedule"
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import ActrejectModal6 from "./enquirysuggestion-v3"
import CarrierWebView1v3 from "./carrierweb-view-v3"
import AguserApi from "../state/AguserApi"
import { IconButton } from "@mui/material"
import EntityV2Api from "../state/entities-api"
import backarrow  from "../assets/images/backarrow.svg";
import { Route } from "../v3-componets/AgRoute";
import { Details } from "../v3-componets/AgDetails";
import { loader } from "../util/loader";
import BookingsApi from "../state/bookings-api";
import AgNewModal from "../components/AgnewModal";
import EntityUtils from "../util/entityUtils";

export default function ViewEnquiryv3(props) {
    const [space, setSpace] = useState('')
    const [graphList, setGraphList] = useState([])
    const [checkgraphList, setCheckGraphList] = useState(false)
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [show, setShow] = useState(false);
    const [prList, setPrList] = useState([])
    const getExchangerates = async( ) =>{
        await Api.getExchangerates()
      }
      const getpr = (d)=>{
        let l = d
        for (let i = 0; i < prList.length; i++) {
            if(d==prList[i]['email']){
                l = `${prList[i]['firstName']} ${prList[i]['lastName']} (${prList[i]['email']})`
            }
        }
        return l
    }
    useLayoutEffect(() => {
        getExchangerates()
        setTimeout(() => {
            setSpace(' ')
        }, 300);
        AguserApi.getUsers4role_new("Procurement", setPrList)
    }, [])
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    useSnapshot(configStore)
    useSnapshot(bookingsStore)
    useSnapshot(taskStore)
    useSnapshot(myactionsstore)
    useSnapshot(AgmodalStore)
    console.log("+=",enquiryStore)
    const cargoForm = useForm({
        defaultValues: {
            mode: "LCL",
            orginScope: false,
            destinationScope: false,
            originType: false,
            destinationType: false,
            showCargoDetailsOutGuage: false,
            showPalletLabel:false
        }
    })
    useEffect(()=>{loader.show()},[])
    useEffect(()=>{
        if((enquiryStore.current._id != undefined && enquiryStore.current._id.length>0) || enquiryStore.current.enqRoute!=undefined && enquiryStore.current.enqRoute.origin != undefined && enquiryStore.current.enqRoute.origin.length>0){
            if(enquiryStore.current.shipmentType.length>3 && enquiryStore.current.shipmentType.toLowerCase().indexOf('fcl')!=-1){
                enquiryStore.current.shipmentType = 'FCL'
                setValue("mode",'FCL');
            }else{
                setValue("mode",enquiryStore.current.shipmentType);
            }
            if(enquiryStore.current.multidest==undefined){
                if(enquiryStore.current.containerDetails==undefined) enquiryStore.current.containerDetails = [new Container()]
                if(enquiryStore.current.cargoDetails==undefined) enquiryStore.current.cargoDetails = [new Cargo()]
                setValue("multidest", [
                    {
                        destination:enquiryStore.current.enqRoute.destination,
                        cargoDetails:enquiryStore.current.cargoDetails,
                    }
                ]);
                enquiryStore.current.multidest = [
                    {
                        destination:enquiryStore.current.enqRoute.destination,
                        cargoDetails:enquiryStore.current.cargoDetails,
                    }
                ]
            }else{
                setValue("multidest",enquiryStore.current.multidest)
            }
            if(props.showbooking){
                setValue("fbaalways","Yes")
            }else{
                setValue("fbaalways","No")
            }
            setValue("containerDetails",enquiryStore.current.containerDetails)
            setValue("origin",enquiryStore.current.enqRoute.origin)
            setValue("cargoreadinessdate",enquiryStore.current.enqRoute.cargoReadinessDate)
            setValue("scope",enquiryStore.current.shipmentScope)
            setValue("shipmentType",enquiryStore.current.shipmentType)
            setValue("customerreference",enquiryStore.current.enqRoute.customerReferenceNum)
            setValue("hazardous",enquiryStore.current.hazardous === "No" ? false :true)
            setValue("quoteClass",enquiryStore.current.quoteClass)
            setValue("packingGroup",enquiryStore.current.packingGroup)
            setValue("temperature",enquiryStore.current.temperature)
            setValue("cargoValue",enquiryStore.current.cargoValue)
            setValue("unnumber",enquiryStore.current.unnumber)
            setValue("nonStackable", enquiryStore.current.nonStackable === "No" ? false :true)
            setValue("temperatureControlled", enquiryStore.current.temperatureControlled === "No" ? false :true)
            setValue("minTemperature",enquiryStore.current.minTemperature)
            setValue("maxTemperature",enquiryStore.current.maxTemperature)
            setValue("stuffingType",enquiryStore.current.stuffingType)
            setValue("destuffingType",enquiryStore.current.destuffingType)
            setValue("status",enquiryStore.current.status)
            setValue("cargoDimensionUnit",enquiryStore.current.cargoDimensionUnit)
            setValue("enqRoute",enquiryStore.current.enqRoute)
            setValue("vehicledetails",enquiryStore.current.vehicles)
            setValue("isFba",enquiryStore.current.fba === "Yes" ? true :false)
            setValue("ior",enquiryStore.current.fbaIorRequired === "Yes" ? true :false)
            setValue("occ",enquiryStore.current.fbaOCC === "Yes" ? true :false)
            setValue("dcc",enquiryStore.current.fbaDCC === "Yes" ? true :false)
            setValue("survey",enquiryStore.current.fbaSurvey === "Yes" ? true :false)
            setValue("storageAtDestination",enquiryStore.current.fbaSAD === "Yes" ? true :false)
            setValue("palletizationandlabelling",enquiryStore.current.fbaPAL === "Yes" ? true :false)
            setValue("days",enquiryStore.current.fbaDays!=undefined?enquiryStore.current.fbaDays:0)
            setValue("pick_up_by_amazon",enquiryStore.current.pick_up_by_amazon === "Yes" ? true :false)
            setShow(true)
            loader.hide()
        }
    },[enquiryStore.current])
    const { control, watch, register, setValue, handleSubmit, reset, clearErrors, formState, trigger, getFieldState } = cargoForm;
    const { changeCurrentMenu } = useGlobalState()
    const shipConsMigratDate = new Date("2024-05-28");
    const rec = contractsStore.current
    let e = enquiryStore.current
    let activeProduct = e.shipmentType.substr(0, 3).toLowerCase()
    console.log(enquiryStore.current)
    //console.log('>>>>>>>>>>>', rec, props.viewonly)
    /*if (!rec._id && props.viewonly) return <></>
    let title = `Create Booking - ${rec._id}`
    if (!props.viewonly) title = 'Enquiries'*/
    let title
    if (props.showbooking) {
        if (!bookingsStore.current.erpJobId) bookingsStore.current.erpJobId = ''
        title = 'Booking Detail - ' + bookingsStore.current._id
        configStore.homescreenCurrentShipmentScope = contractsStore.current.shipmentScope
    } else {
        title = 'Enquiry Detail - ' + enquiryStore.current._id
    }
    let displayCargo4fcl = false
    {
        let e = enquiryStore.current
        let stype = e.shipmentType.substr(0, 3).toLowerCase()
        configStore.homescreenCurrentShipmentType = stype
        if (stype === 'fcl' && e.shipmentScope === 'Port-to-Door') {
            displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore.current.containerDetails)
            if (e.destuffingType === 'Dock')
                displayCargo4fcl = true
        }
        else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Port') {
            displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore.current.containerDetails)
            if (e.stuffingType === 'Dock')
                displayCargo4fcl = true
        }
        else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Door') {
            displayCargo4fcl = true
            if (e.stuffingType === 'Factory' && e.destuffingType === 'Factory')
                displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore?.current?.containerDetails)
        }else if(stype==='fcl'){
            displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore.current.containerDetails)
        }

        //console.log('#########>>>', stype, displayCargo4fcl)
    }
    const updatecusref = (e) =>{
        if(props.showbooking && rec['customerReferenceNum']!=e){
            rec['customerReferenceNum'] = e
            BookingsApi.setcustrefBooking(bookingsStore.current._id, rec)
        }
    }
    useEffect(() => {
        console.log('*** subscribing ***********')
        const unsubscribe = subscribe(myactionsstore, () => {
            let graphList1 = []
            for (let i = 0; i < myactionsstore.list.length; i++) {
                let rec = myactionsstore.list[i]
                if (rec['booking']['containershow'] == undefined) {
                    rec['booking']['containershow'] = -1
                }
                let oneGraph = []
                oneGraph = Visibility.GraphLine(rec)
                // if (rec.shipmentType !== 'Air') oneGraph = Visibility.oceanGraphLine(rec)
                // else oneGraph = Visibility.airGraphLine(rec)
                let activeOn = false
                let lastactive = -1
                for (let i = oneGraph.length - 1; i >= 0; i--) {
                    let node = oneGraph[i]
                    if (rec.status_closed == 'Y' || rec.status_closed == 'Yes' || rec.booking.status == 'ARCHIVED') {
                        node.isActive = true
                    }
                    if (node.isActive && !activeOn) {
                        node.activelines = 1
                        lastactive = i
                    }
                    if (node.isActive) activeOn = true
                    if (activeOn && !node.isActive) node.isActive = true
                    if (node.isActive && i < lastactive) node.activelines = 2
                }
                graphList1.push(oneGraph)
            }

            setGraphList(graphList1)
        })
        return unsubscribe
    }, [myactionsstore])
    if ((bookingsStore.current.status == 'ARCHIVED' || bookingsStore.current.status == 'INPROGRESS') && graphList.length == 0) {

        console.log(myactionsstore)
        console.log(graphList)
        if (checkgraphList == false) {
            Newapi.getAllDsr4Displaybyid(bookingsStore.current._id)
            setCheckGraphList(true)
        }
    } else {

        console.log(myactionsstore)
        console.log(graphList)
    }

    if (taskStore.triggerActionVisible) return <TaskTrigger />
    if (taskStore.vendorAssignment) return <VendorAssignment />
    return (
        <>
        {
            show && <>
            <ActrejectModal6 type={"cancel"} id={e._id} />
            {space}
            <div className="columns">
                <div className="column"  style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                { loginStore.isClient() &&  <IconButton disableRipple onClick={()=>{
                 bookingsStore.currentBookingsListComponent = "list"
            }}>
                <img src={backarrow}/>
            </IconButton> }
                    <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                        {title}
                    </h5>
                    <></>
                </div>
                <div className="column is-1">
                    {
                        (props.showbooking && !loginStore.isClient() && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.BOOKING_VENDOR)) ?
                            <button disabled={!bookingsStore.current.contract} style={{ marginTop: '10px' }} className="button islink"
                                onClick={async(e) => {
                                    // EntityV2Api.checkMyactionEnt(bookingsStore.current.entityId ,bookingsStore.current.branchId , (data) => {
                                    //     if(data.status === 200) {
                                    //         taskStore.current = new Task('')
                                    //     taskStore.current.initialize(bookingsStore.current, entityStore.entityRec)
                                    //     taskStore.vendorAssignment = true
                                    //     }
                                    //   } )
                                    taskStore.vendorAssignment = true
                                }}>Vendor</button>
                            : <></>
                    }
                </div>
                {/* <div className="column is-2">
                    {
                        (props.showbooking && !loginStore.isClient() && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.BOOKING_ACTION)) ?
                            <button disabled={!bookingsStore.current.contract} style={{ marginTop: '10px' }} className="button islink"
                                onClick={async(e) => {}>Load to ERP</button>
                            : <></>
                    }
                </div> */}
                <div className="column is-1">
                    {
                        (props.showbooking && !loginStore.isClient() && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.BOOKING_ACTION)) ?
                            <button disabled={!bookingsStore.current.contract} style={{ marginTop: '10px' }} className="button islink"
                                onClick={async(e) => {
                                   EntityV2Api.checkMyactionEnt(bookingsStore.current.entityId ,bookingsStore.current.branchId , (data) => {
                                    if(data.status === 200) {
                                        taskStore.current = new Task('')
                                    taskStore.current.initialize(bookingsStore.current, entityStore.entityRec)
                                    taskStore.triggerActionVisible = true
                                    }
                                  } )
                                }}>Action</button>
                            : <></>
                    }
                </div>
                <div className="column is-3">
                    {
                        (!loginStore.isClient() && props.showbooking) ?
                            <div style={!RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CB_ERP_SHIPMENT_NUMBER) ? { display: 'flex', justifyContent: 'center', pointerEvents: 'none', opacity: 0.6 } : { display: 'flex', justifyContent: 'center' }}>
                                <span>
                                    <AgInput label='ERP Shipment #' record={bookingsStore.current}
                                        name='erpJobId' />
                                </span>
                                <span>
                                    <button className="button is-small islink"
                                        style={{ marginTop: '1rem' }}
                                        onClick={async () => {
                                            try {
                                                // if (!(/^([A-Z]){3}([/])([A-Z]){2}([/])\d{2}([/])\d{2}([/])([A-Z]){1}([/])\d{4}/.test(bookingsStore.current.erpJobId))) {
                                                //     configStore.setModalMessage('ERP Job ID is not valid.')
                                                //     return;
                                                // }
                                                if(bookingsStore.current.erpJobId!=undefined && bookingsStore.current.erpJobId.trim().length==0){
                                                    configStore.setModalMessage('ERP Job ID should not save as empty')
                                                    return;
                                                }
                                                let isBookingExistsWithSame = await MonApi
                                                    .find('Bookings', { erpJobId: bookingsStore.current.erpJobId }, { _id: 1 })
                                                    .then(res => Array.isArray(res) && res?.length > 0);
                                                if (isBookingExistsWithSame) {
                                                    configStore.setModalMessage('ERP Job ID with booking is already exists.')
                                                    return;
                                                }
                                                let ret = await MonApi.updateOne('Bookings',
                                                    { _id: bookingsStore.current._id },
                                                    { erpJobId: bookingsStore.current.erpJobId })
                                                if (ret.error) configStore.setModalMessage('' + ret.error)
                                                else configStore.setModalMessage('ERP Job ID updated successfully.')
                                            } catch (e) {
                                                configStore.setModalMessage('Error trying to save ERP ID')
                                            }
                                        }}>
                                        Save
                                    </button>
                                </span>
                            </div>
                            : <></>
                    }
                </div>
            </div>
            <div className="columns">
                <div className="column is-12 m-0 p-0">
                <div className="w-full flex flex-col gap-[8px]">
       <form noValidate  style={{width:"100%",display:"flex",flexDirection:"column"}}>
       <div className="flex flex-row gap-[10px]">
        <div className="flex flex-col">  
       <Route form={cargoForm} viewonly={props.viewonly=='1'} callback={updatecusref}/>
       { props.showbooking === "1" && <button type="button" style={{ float: 'left', marginTop:"10px" }}
                                className="button is-rounded is-small islink" onClick={(e) => {
                                    e.stopPropagation();
                                    let customTC = []
                                    if (rec['customTC']) {
                                        customTC = rec['customTC'].split(/\r?\n/)
                                    }
                                    rec['tc']?.map(e => customTC.push(e))
                                    rec['temptc']?.map(e => customTC.push(e))
                                    configStore.setModalMessage("", [], false, customTC)
                                    // configStore.setModalMessage("hja")
                                }}>Terms and Conditions</button>}
        </div>
       <Details form={cargoForm} viewonly={props.viewonly=='1'}/> 
    
       </div>
       </form>
       </div>
                </div>
                
                
            </div>
            {
                (props.showbooking && !loginStore.isClient() && bookingsStore.current && bookingsStore.current.contract && bookingsStore.current.contract.procurement!=undefined && bookingsStore.current.contract.procurement.length>0) ?
                    <div className="columns mt-3">
                        <div className="column is-12">
                        <strong>Procurement :</strong> {getpr(bookingsStore.current.contract.procurement)}
                        </div>
                    </div> : <></>
            }
            {
                (props.showbooking && !loginStore.isClient() && bookingsStore.current.createdFromPricing && AgmodalStore.modalPage !== "ViewEnquiry-v3") ?
                    <BookingSchedule booking={bookingsStore.current} /> :
                    <></>
            }

            {
                (props.showbooking) ?
                    <div className="columns">
                        <div className="column is-12">
                            {
                                (new Date (bookingsStore.current.bookingDate) >= shipConsMigratDate)?
                                <CarrierWebView2v3 showbooking={props.showbooking} />:<CarrierWebView1v3 showbooking={props.showbooking} />
                            }
                        </div>
                    </div>
                    : <></>
            }
            {
                (e.status === 'CANCELLED') ? <div style={{ textAlign: 'center' }}>
                <strong>Reason:</strong> {e.reason}
                </div>
                :<></>
            }
            {
                (!props.showbooking && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENQUIRYTOQUOTATION)) ?
                    (e.status !=='CANCELLED')?
                     <div className="columns">
                            <div className="column is-9">
                                <button style={{ float: 'right' }} className="button is-danger is-small is-rounded" onClick={() => {
                                    configStore.quotationModalVisible4 = true;
                                    AgmodalStore.apiCall = false;
                                }}>
                                    Cancel Enquiry</button>
                            </div>
                            <div className="column is-3">
                                <button style={{ float: 'right' }} disabled={isButtonClicked} onClick={() => {
                                    setButtonClicked(true);
                                    configStore.setModalMessage('Generating quotation from enquiry')
                                    EnquiriesApi.updateToQuote(e._id, () => {
                                        Api.getQuote(e._id)
                                        AgmodalStore.pageVisible = false;
                                        changeCurrentMenu('Quotationv3')
                                        AgmodalStore.modalPage = 'Quotev3';
                                        AgmodalStore.pageVisible = true;
                                    })

                                }}
                                    className="button is-small is-rounded islink">
                                    Generate Quotation from this Enquiry
                                </button>
                            </div>
                        </div>:<></>
                    : <></>
            }

            {
                ((props.showbooking && (bookingsStore.current.status == 'ARCHIVED' || bookingsStore.current.status == 'INPROGRESS') && graphList.length != 0)) ? <>
                    <hr />
                    <div>
                        <div className="box is-fullwidth">
                            {
                                graphList.map((e, i) => {
                                    let e1 = myactionsstore.list[i]
                                    return <VisibilityGraph graphNodes={e} dsrRec={e1} hideedit={true} />
                                })
                            }
                        </div>
                    </div>
                    {/* <h1 style={{textAlign:"center"}}><strong>DSR</strong></h1>
                    <ShipmentGraph graphNodes={graphList[0]} /> */}
                </> : <></>
            }
            </>
        }
        </>
    )
}