import { useState } from "react"
import Utils from "../util/Utils"

export class graphNode {
    constructor() {
        this.display = false
        this.r1 = ''
        this.r2 = ''
        this.isActive = false
        this.graphic = 'middle' // origin, destination, left, right, middle, line
        this.r3 = ''
        this.r4 = ''
        this.activelines = 0
    }
}
export function Dot(props) {
    let dotted = `repeating-linear-gradient(
        to right,
        black,
        black 3px,
        white 3px,
        white 6px
      )`
    //let bg2 = '#acb5fa'
    let roundBack = '#bbbbbb'
    if (props.isActive) roundBack = '#eea724'
    let normal = `#bbbbbb`
    let active = '#6699ff'
    let bg1 = normal
    let bg2 = normal
    if (props.activelines >= 1) bg1 = active
    if (props.activelines >= 2) bg2 = active
    let opacity = '1'

    if (props.left) bg1 = 'white'
    if (props.right) bg2 = 'white'
    if (props.origin) { bg1 = 'white'; bg2 = dotted }
    if (props.destination) { bg2 = 'white'; bg1 = dotted }
    if (props.transparent) opacity = '0'
    return (
        <div style={{ position: 'relative', marginBottom: '10px' }}>
            <div style={{
                marginTop: '5px',
                width: '50%',
                height: '5px',
                background: bg1,
                float: 'left',
                zIndex: 0
            }}>
            </div>
            <div style={{
                marginTop: '5px',
                width: '50%',
                height: '5px',
                background: bg2,
                float: 'right',
                zIndex: 0
            }}>
            </div>
            <div style={{
                position: 'absolute',
                top: '1px',
                left: '50%',
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                opacity: opacity,
                backgroundColor: roundBack,
                float: 'center',
                zIndex: 9
            }}></div>
        </div>
    )
}

export default function ShipmentGraph(props) {
    let { graphNodes,callback = f => f} = props
    let numCols = graphNodes.length
    const [showdest,setShowdest] = useState(false)
    let row1 = []
    let row2 = []
    let row3 = []
    let row4 = []
    let row5 = []
    let row6 = []
    let grow = []
    for (let i = 0; i < numCols; i++) {
        let node = { ...graphNodes[i] }
        console.log("node in card",node,i);
        if (!props.insidepricing) {
            node.r1 = Utils.reformatSuffixedDate(node.r1)
            node.r3 = Utils.reformatSuffixedDate(node.r3)
        }
        // console.log("node",node);
        let style = {
            maxWidth: '10rem',
            border: 'none',
            padding: '0px',
            wordWrap: 'break-word',
        }
        let style5 = {
            border: 'none',
            padding: '0px',
            // wordWrap: 'break-word',
            overflowWrap: 'anywhere',
            position: "relative",
            left: "1em",
            top: "1em",
            right: "1em"
        }
        let style6 = {
    position: "static",
    left: "25%",
    background: "#fff",
    zIndex: "100000",
    textAlign: "left",
    border: "1px solid",
    padding: "2%"
        }
        let r2style = {
            maxWidth: '10rem',
            border: 'none',
            padding: '0px',
            wordWrap: 'break-word',
            display: 'table-cell',
            verticalAlign: 'bottom'
        }

        let r1style = { ...style }
        let r3style = { ...style }
        const close = ()=>{
            if(showdest){
                setShowdest(false)
            }else{
                setShowdest(true)
            }
        }
        const gclose = ()=>{
            if(showdest){
                setShowdest(false)
            }
        }
        callback(gclose)
        if (node.r1 && (node.r1.startsWith('ETA') || node.r1.startsWith('ETD') || node.r1.startsWith('Cut-Off') || node.r1.startsWith('EDD'))) {
            //if (node.isActive) node.r1 = ''
        } else {
            r1style['color'] = '#7777ee'
        }
        if (node.r3 && (node.r3.startsWith('ETA') || node.r3.startsWith('ETD') || node.r3.startsWith('Cut-Off') || node.r1.startsWith('EDD'))) {
            //if (node.isActive) node.r3 = ''
        } else {
            r3style['color'] = '#7777ee'
        }
        let arr = []
        if (node.r1 && node.r1.split) arr = node.r1.split(' ')
        if (arr.length > 1) {
            node.r1 = <div><strong><span style={r1style}>{arr[0]}</span></strong><span> {arr.slice(1).join(' ')}</span></div>
        }
        arr = []
        if (node.r3 && node.r3.split) arr = node.r3.split(' ')
        if (arr.length > 1) {
            node.r3 = <div><strong><span style={r3style}>{arr[0]}</span></strong><span> {arr.slice(1).join(' ')}</span></div>
        }
        row1.push(<td style={r1style}>{node.r1}</td>)
        if(node.r2==="Multiple"){
                row2.push(<td style={r2style}><div >
                    {
                        (showdest)?<div style={style6}>
                            <p style={{textAlign:"right",
    color: "#ff0000"}} onClick={()=>{
        close()
    }}>x</p>
                        {node.multidest!=undefined && node.multidest.map((e,j)=>{
                            return <>
                            <p style={{color: "#3e8ed0"}}>Destination{j+1}</p>
                            <p>{e.destination}</p>
                            </>
                        })}
                        
                        </div>:""
                    }
                    <span style={{color: "#3e8ed0",
            textDecoration: "underline"}} onClick={()=>{
                close()
            }}>{node.r2}</span>
                    </div></td>)
        }else{
            if(node.al && node.al.length > 0){
                row2.push(<td><div style={style5}>{node.r2}</div><div style={{ position: "relative", left: "50%" }}>{node.al}</div></td>)
            }else{
                row2.push(<td style={r2style}>{node.r2}</td>)
            }
        }
            if(node.bl && node.bl.length > 0){
                row3.push(<td><div style={style5}>{node.r3}</div><div style={{ position: "relative", left: "45%" }}>{node.bl}</div></td>)
            }else{
                row3.push(<td style={r3style}>
                    <p>{node.r3}</p>
                    {/* <p>{node.vl && node.vl.length > 0 && !(node.tvl && node.tvl.length > 0) || (node.tcn && node.tcn.length > 0)?"":node.r7}</p>*/}
                    <p >{!(node.vl && node.vl.length > 0) && !(node.cn && node.cn.length > 0)?<strong style={{color:" rgb(119, 119, 238)"}}>{node.r7}</strong>:""}</p> 
                    </td>)
            }
        // (node.r2 == "Chennai (ex Madras)") ?row7.push(<td><div style={{}}>{node.r3}</div><div style={{ position: "relative", left: "45%" }}>{node.bl}</div></td>):row7.push(<td style={r3style}>mhgmhg</td>)
        // row3.push(<td><div style={style5}>{node.r3}</div><div style={{position:"relative",left:"45%"}}>{node.bl}</div></td>)
        row4.push(<td style={{ maxWidth: '10rem', border: 'none', wordWrap: 'break-word' }}>{node.r4}</td>)

        if (node.vl && node.vl.length > 0) {
            row5.push(<td style={{border:"none"}}>{`(${node.vl}) `}<i class="fa fa-info-circle" aria-hidden="true" title="This refelects the number of vehicles that have achieved the milestone out of total number of vehicles."></i>
            <p style={{color:"#7777EE",fontWeight:900}}>{node.r7}</p></td>)
        }
        if (node.cn && node.cn.length > 0) {
            row5.push(<td style={{border:"none"}}>{`(${node.cn}) `}<i class="fa fa-info-circle" aria-hidden="true" title="This refelects the number of Containers that have achieved the milestone out of total number of containers."></i>
            <p style={{color:"#7777EE",fontWeight:900}}>{node.r7}</p></td>)
        }
        if (node.tvl && node.tvl.length > 0) {
            row6.push(<td style={{border:"none"}}>{`(${node.tvl}) `}<i class="fa fa-info-circle" aria-hidden="true" title="This refelects the number of vehicles that have achieved the milestone out of total number of vehicles."></i></td>)
        }
        if (node.tcn && node.tcn.length > 0) {
            row6.push(<td style={{border:"none"}}>{`(${node.tcn}) `}<i class="fa fa-info-circle" aria-hidden="true" title="This refelects the number of Containers that have achieved the milestone out of total number of containers."></i></td>)
        }
        if (!node.vl && !node.cn) {
            row5.push(<td style={{border:"none"}} ></td>)
        }
        if (!node.tvl && !node.tcn) {
            row6.push(<td style={{border:"none"}}></td>)
        }

    }

    for (let i = 0; i < numCols; i++) {
        let node = graphNodes[i]
        let p = { isActive: node.isActive }
        if (node.graphic === 'origin') p.origin = true
        if (node.graphic === 'destination') p.destination = true
        if (node.transparent) p.transparent = true
        if (i === 0) p.left = true
        if (i === numCols - 1) p.right = true
        p.activelines = node.activelines
        grow.push(<td style={{ border: 'none', padding: '0px' }}><Dot {...p} /></td>)
    }
    return (
        <table className='schtable' style={{
            width: '100%',
            // display:"flex",
            // flexDirection:"row",
           alignItems:"center",
            textAlign: 'center',
            fontSize: '0.8rem',
            border: 'none'
        }}>
            <tbody>
                <tr style={{ border: 'none' }}>{row6}</tr>
                <tr>{row1}</tr>
                <tr>{row2}</tr>
                <tr>{grow}</tr>
                <tr>{row3}</tr>
                <tr>{row4}</tr>
                <tr style={{border:"none"}}>{row5}</tr>
            </tbody>
        </table>
    )
}
export function ShipmentTable(props) {
    const { graphNodes } = props
    let numCols = graphNodes.length
    let row1 = ''
    let row2 = ''
    let row3 = ''
    let row4 = ''
    console.log('\n\n*** processing ...\n')
    for (let i = 0; i < numCols; i++) {
        let node = { ...graphNodes[i] }
        console.log('value of node = ', node)
        row1 += `<td style="text-align: center;border: 1px solid red !important; padding-left: 1rem;padding-right: 1rem;">${node.r1}</td>`
        row2 += `<td style="text-align: center;border: 1px solid lightgrey; padding-left: 1rem;padding-right: 1rem;">${node.r2}</td>`
        row3 += `<td style="text-align: center;border: 1px solid white; padding-left: 1rem;padding-right: 1rem;">${node.r3}</td>`
        row4 += `<td style="text-align: center;border: 1px solid white; padding-left: 1rem;padding-right: 1rem;">${node.r4}</td>`
    }

    let ret= `<table style="border: 1px solid red; text-align: center;">
            <tbody>
                <tr>${row1}</tr>
                <tr>${row2}</tr>
                <tr>${row3}</tr>
                <tr>${row4}</tr>
            </tbody>
        </table>
    `
    console.log('Returning', ret, '\n**********')
    return ret
}