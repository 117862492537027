import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { AgmodalStore, configStore, loginStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";

export default function Ccchp(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage >= 1) {
        return (
            <div className="box">
                <p>Please note that you have not yet handed-over the cargo.</p>
                <p>Cargo hand-over Cut-off is {task.cutOff}</p>
                <p>
                    <span>
                        {
                            (stage === 1) ?
                                <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        taskStore.current.stage = 2
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        taskStore.setCustomerComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                            AgmodalStore.pageVisible = false
                                            AgmodalStore.apiCall = true
                                        }
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>
            </div>
        )
    }
}
export function CcchpTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    const [disableButton, setDisableButton] = useState(true)
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            let disable = false
            if (taskStore.current.cutOff.length == 0) {
                disable = true
            }
            setDisableButton(disable)
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            {/* <TaskDatetime /> */}
            <hr />
            <h5 className="title is-6">Delay in Cargo Hand-over</h5>
            <hr />
            <table className="table is-fullwidth is-striped is-narrow" style={{ fontSize: '0.85rem' }}>
                <tr>
                    <td>Cut-off</td>
                    <td><input type='date' value={task.cutOff} onChange={(e) => task.cutOff = e.target.value} /></td>
                </tr>
            </table>
            <p>
                <button className="button islink" disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        setDisableButton(true)
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}