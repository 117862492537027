import { useSnapshot } from "valtio"

import AguserApi from "../state/AguserApi"
import { Buffer } from 'buffer'
import {
  AgmodalStore,
  ApprovalTabStore,
  DriverStore,
  EntityV2Store,
  configStore,
  loginStore,
  routerStore,
} from "../state/CommonState"
import { EntityTypeV2 } from "../state/Types"
import { useEffect, useRef, useState } from "react"
import { BsFillEyeFill } from "react-icons/bs"
import { CiFileOff } from "react-icons/ci";
//import { Col12, Col1,Col2, Col3, Col4, Col5, Col6, Columns } from "../Controls";
import {
  Col12,
  Col1,
  Col2,
  Col3,
  Col4,
  Col8,
  Col7,
  Col5,
  Cols,
  Col6,
  Columns,
  Col10,
  Col11,
} from "../Controls"

import { loader } from "../util/loader"
import RoleUtils from "../util/RoleUtils"
import Approval_Apis from "../state/approval-api"
import DriverApi from "../state/driver_api"
import MainDrivers from "../drivers/main-drivers"
import { ReactComponent as Loader } from "../assets/loader.svg"

export default function EditDriverApproval(props) {
  const Driver = DriverStore.DriversRec
  const APP = ApprovalTabStore.ApprovalRec

  const [FormUserStatus, setFormUserStatus] = useState("")
  const [Remarks, setRemarks] = useState("")
  const [errors, setErrors] = useState({})
  const [pendingErrors, setPendingErrors] = useState({})
  const [approveCheckbox, setApproveCheckbox] = useState(false)
  const [approvalStatus, setApprovalStatus] = useState(null)
  const [message, setMessage] = useState("")
  const [downloadFormData, setDownloadFormData] = useState({})
  const [cursor, setCursor] = useState(0)
  const [subbtnstatus, setsubbtnstatus] = useState(false)
  const [RDIR, setRDIR] = useState(false)
  const [ROLE, setROLE] = useState("")
  const ref = useRef(null)
  const hiddenFileInput1 = useRef()
  let title = ""
  switch (props.mode) {
    case "edit":
      title = `Modify (${Driver?._id}) ${Driver?.Status}`
      break
    case "view":
      title = `View (${Driver?._id}) ${Driver?.Status}`
      break
    case "new":
      title = "Create Driver"
      break
    default:
      title = "Driver"
      break
  }
  // useSnapshot(EntityV2Store)
  useSnapshot(ApprovalTabStore)
  useSnapshot(AgmodalStore)
  useSnapshot(loader)
  useEffect(() => {
    const input = ref.current
  }, [ref, cursor])
console.log("hhhhhhhhhhhhhhh");
  useEffect(() => {
    setROLE(ApprovalTabStore.ApprovalRec.userRole)
    setFormUserStatus(ApprovalTabStore.ApprovalRec.action)
  }, [])
  const handleVerifyRadioChange = (event) => {
    // Driver.remarks = ""
    setRemarks("")
    setApprovalStatus(event.target.value)
    setApproveCheckbox(true)
    setMessage("")
  }

  const handleMessageChange = (e) => {
    // Driver.remarks = e.target.value
    setRemarks(e.target.value)
  }


  const timelinepushfn = (r) => {
    let timeline = {
      date: Date.now(),
      event: r,
      user: loginStore.email,
      role: RoleUtils.getUserRole()
    }
    Driver.timeline.push(timeline)
  }
  useEffect(() => {
    setRemarks(Driver?.Remarks)
    if(!Driver.timeline || Driver.timeline === null){
      Driver.timeline = []
    }
  }, [])
  
  const handleSubmit = async (e) => {
    // e.preventDefault()
    setsubbtnstatus(true)
    console.log("ROLE------------>", ROLE);
    // if (Driver.entityType === "Customer") {
      if (approvalStatus === "approve" ) {
        const chkBox = document.getElementById("approveChkbox")?.checked
        if (chkBox) {
          await timelinepushfn("Verified")
          let dataUpdate = {
            formdata: Driver,
            userTypeObj:{
            role: ROLE,
            status:"approve",
            updatedBy: loginStore.email,
            },
          }
          await Approval_Apis.driUpdateStatus(dataUpdate, setsubbtnstatus,(d)=>{
            setsubbtnstatus(false)
          })
        }
        else {
          console.log("pls");
          setsubbtnstatus(false)
        } 

      } else if (approvalStatus === "reject") {
        if (Remarks.length < 1) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Please enter remarks , it should contain atleast 3 characters")
        }
        if (Remarks.trim().length < 3) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Remarks should contain atleast 3 characters")
        }
        await timelinepushfn("Rejected")
        let dataUpdate = {
          formdata: Driver,
          userTypeObj:{
            role: ROLE,
            status:"reject",
            rejectedrole: Driver.role,
            updatedBy: loginStore.email,
          },
          Remarks
        }
        await Approval_Apis.driUpdateStatus(dataUpdate, setsubbtnstatus,(d)=>{
          setsubbtnstatus(false)
        })
      } 
      else{
        setsubbtnstatus(false)
      }

     
    

    
    // setsubbtnstatus(false)
  // }

  } 
  const onRedirect =async (event)=>{
    setsubbtnstatus(true)
    console.log(event);
    // if(!event.detail || event.detail == 1){
      await timelinepushfn("Rejection-Acknowledged")
      let dataUpdate = {
        formdata: Driver,
        userTypeObj:{
        role: ROLE,
        status:"approve",
        updatedBy: loginStore.email,
        },
      }
       await Approval_Apis.driUpdateStatus(dataUpdate, setsubbtnstatus,async(d)=>{
        if (d.Status === "Rejected") {
          await DriverApi.viewDriver(Driver.DlNumber, async(d)=>{
            if (d.Status === "Rejected") {
              setRDIR(true)
              setsubbtnstatus(false)
            }
          })
          }
       })
      //  AgmodalStore.apiCall = true;
    // }

  }
  const dateFormat = (date)=>{
    return date?.split("-").reverse().join("/")
  // return   dd[1]+"/"+dd[2]+"/"+dd[0]
  }
  return (
    <>
    
    { RDIR ? <MainDrivers fn={RDIR}/>: <>
      <div class="main-content" style={{ zoom: "0.9" }}>
        <div className="header-wrapper">
          <div className="left-header">
            <div className="page-heading" >

              <span className="heading">
                {Driver.DlNumber}/{FormUserStatus}{' '} 
              </span>

            </div>
          </div>
        </div>
        <section
          class="section section-padding"
          style={{ paddingTop: "0px" }}
        >
          <Cols>
            <Col7 style={{ zoom: "0.9",height:"70vh",overflowY:"scroll",display:"flex",flexWrap:"wrap" }}>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Driver Name</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Driver.Name}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Mobile Number</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Driver.MobileNumber}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>DL Number</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                    {Driver.DlNumber}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                {/* <span className="ml-2 mr-1 is-size-4"> */}
                      {Driver.documents[0]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Driver.documents[0]?.pkey +
                          "/" +
                          Driver.documents[0]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    {/* </span> */}
                </Col2>
                    </Cols>


                    </Col12>

                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>DL Expiry Date</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{dateFormat(Driver.DlExpiryDate) || "NA"}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>State</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Driver.State?.split("/")[0] || "NA"}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>City</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Driver.City || "NA"}</Col12>
                </div>
                <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Date Of Birth</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{dateFormat(Driver.DOB)  || "NA"}</Col12>
                </div>


              
            </Col7>
            <Col5 style={{position:"relative",height:"95vh"}}>
              <div
                style={{
                  fontWeight: "bold",
                  color: "purple",
                  fontSize: "1.1rem",
                }}
                className=""
              >
                TimeLine
              </div>
              <table
                className="table is-fullwidth is-narrow timeline_table"
                style={{ zoom: "0.8" }}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Event</th>
                    <th>User</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {Driver?.timeline?.map((r, i) => {
                    return <>
                      <tr key={i}>
                        <td>{new Date(Driver?.timeline[i].date).toLocaleString()}</td>
                        <td>{Driver.timeline[i].event}</td>
                        <td>{Driver?.timeline[i]?.user}</td>
                        <td>{Driver.timeline[i].role}</td>
                      </tr>
                    </>
                  })}
                </tbody>
              </table>
              <div className="approve-container">
              {(
            <>
              {(ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending") && (
                <p className="is-size-5 has-text-weight-bold">
                  Document Verification
                </p>

              )
              }
              {/* {true && (
                <p className="is-size-5 has-text-weight-bold">
                  Finance Approvals for this Credit Facility
                </p>
              )
              } */}
              {(ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending") && (
                <div className="is-flex is-justify-content-space-between">
                  <span className="is-size-6" style={{ marginTop: "1%" }}>
                    <div>
                      <label>
                        <input
                          type="radio"
                          className="is-size-2"
                          name="approvalStatus"
                          value="approve"
                          checked={approvalStatus === "approve"}
                          onChange={handleVerifyRadioChange}
                        />
                        Approve
                      </label>
                      <label style={{ marginLeft: "10px" }}>
                        <input
                          type="radio"
                          className="is-size-2"
                          name="approvalStatus"
                          value="reject"
                          checked={approvalStatus === "reject"}
                          onChange={handleVerifyRadioChange}
                        />
                        Reject
                      </label>
                    </div>
                  </span>
                </div>
              )}

              {approvalStatus === "reject" && (
                <div>
                  <textarea
                    //  id="rejectremarksid"
                    className="px-2"
                    rows="4"
                    cols="50"
                    placeholder="Enter your Remarks"
                    value={Remarks}
                    onChange={handleMessageChange}
                    //onChange={approvebtnnfn}
                    style={{ marginTop: "10px", border: "1px solid #d1d1e0" }}
                  />
                </div>
              )}
              {(approvalStatus === "approve") && (
                <div>
                  Do you want to approve? <span></span>
                  <input
                    type="checkbox"
                    className="is-size-2"
                    name="approvalStatus"
                    // value="approve"
                    id="approveChkbox"
                  // onChange={approvebtnnfn}
                  />
                </div>
              )}
            </>
          )}
            {(ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending") &&
            (
              <div>
                <span>
                  <button
                    className="button_cancel btn_height"
                    style={{ marginLeft: "3px", paddingLeft: "10px" }}
                    onClick={() => {
                      EntityV2Store.EntityRec.set(new EntityTypeV2())
                      AgmodalStore.pageVisible = false;
                      AgmodalStore.apiCall = true;
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    className="button_submit btn_height"
                    id="approvebtnid"
                    onClick={(e) => handleSubmit(e)}
                    disabled={subbtnstatus}
                  >
                  {!subbtnstatus ? "SUBMIT" : <Loader className="spinner" />}
                  </button>
                </span>
              </div>
            )
          }
 
              </div>
              {(ROLE === "FINANCE" || ROLE === "Operations"|| ROLE === "SALES") && (ROLE === "SALES" || Driver?.timeline?.find((d)=>d.event === "Rejection-Acknowledged")) && (
              <>
            <div style={{border:"1px solid #f5f5f5"}}>
              <p style={{padding:"1rem",backgroundColor:"#f2f2f2"}}>Rejection Remarks</p>
             <p style={{padding:"1rem"}}>{Driver?.Remarks}</p> 
            </div>  
            {(APP.status ==="Pending") && ROLE === "SALES" && <button className="button_submit" style={{padding:".7rem",}} disabled={subbtnstatus} onClick={(e)=>onRedirect(e)}>Acknowlegement</button>}

            </>
          )

          }

            </Col5>
          </Cols>
        </section>




        <hr />
      </div>
      </>
} </>
  )

  function clearErrorMessages(r) {
    pendingErrors[r] = ""
    errors[r] = ""
    setPendingErrors(pendingErrors)
  }

}
