import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { AgmodalStore, configStore, loginStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import Billchecklist from "./Bill-check-list";
 
export default function Boechecklistapproval(props) {
    useSnapshot(taskStore)
    const [checkBtnk, setcheckBtnk] = useState(false)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    console.log("taskkkk",task);
    let shipmentType = task.shipmentType.substr(0, 3).toLowerCase()
    let stage = task.stage

    if (stage === 1) {
        return (
            <div className="box">
                <p>Please approve the Bill of Entry Checklist</p>
                <p></p>
                {
                    (task.remarks) ?
                        <p>Remarks : {task.remarks}</p>
                        : <></>
                }
                <hr />
                <Taskfileapproval />
                <hr />
                <p>
                    <span>
                        <button style={{ float: 'right' }} className="button islink"
                            onClick={async (e) => {
                                let task = taskStore.current
                                let ret = validateFileApproval(task)
                                if (ret.errors.length > 0) {
                                    configStore.setModalMessage(ret.errors.join(','))
                                    return
                                }
                                if (ret.rejected)
                                    [task.stage, task.pendingWith] = [2, 'AGRAGA']
                                else {
                                    [task.stage, task.pendingWith] = [3, 'AGRAGA']
                                }
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }}>Submit</button>
                    </span>
                </p>
            </div>
        )
    }
    if (stage === 2) {
        return (
            <>
                <p>The Bill of Entry Checklist was rejected. Please see reason below and upload revised document.</p>
                <hr />
                <Taskfiletable showRejectReason={true} />
                <p>
                    <span><strong>Remarks</strong></span>
                    <br />
                    <span><textarea cols='50'
                        value={taskStore.current.remarks} onChange={(e) => {
                            taskStore.current.remarks = e.target.value
                        }} /></span>
                </p>
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink"
                            onClick={async (e) => {
                                taskStore.current.stage = 1
                                taskStore.current.pendingWith = 'CUSTOMER'
                                taskStore.current.customerStatus = 'PENDING'
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Submit</button>
                    </span>
                </p>
            </>
        )
    }
    if (stage >= 3) {
        return (
            <>
                <p>The Bill of Entry Checklist Document has been approved</p>
                <hr />
                {(task.customerStatus === "COMPLETED" && stage > 3) || shipmentType == 'air'?<Taskfiledisplay />:<></>}
                {stage === 3 && shipmentType != 'air'?<Billchecklist type="boe" callback={(e)=> setcheckBtnk(e)}/>:null}
                {(checkBtnk || shipmentType == 'air') &&<p>
                    <span>
                        {
                            (stage === 3) ?
                                <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        taskStore.current.stage = 4
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                            AgmodalStore.pageVisible = false
                                            AgmodalStore.apiCall = true
                                        }
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>
    }
            </>
        )
    }
}
export function BoechecklistapprovalTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    const [disableButton, setDisableButton] = useState(true)
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            let disable = false
            if (taskStore.current.dueDate.length == 0) {
                disable = true
            }
            if(!disable){
                let checkfile = []
                taskStore.current.files.map((e) =>{
                    if(checkfile.indexOf(e.label) == -1){
                        if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                            disable = true
                        }else{
                            if(taskStore.current.original_file.indexOf(e.label) != -1){
                                checkfile.push(e.label)
                            }
                        }
                    }
                })
            }
            setDisableButton(disable)
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <TaskDatetime />
            <hr />
            <strong>Upload Bill of Entry Checklist</strong>
            <Taskfiletable />

            <p>
                <button className="button islink" disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        setDisableButton(true)
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}