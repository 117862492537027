import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { AgmodalStore, bookingsStore, configStore, loginStore, modalStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval from "./task-file-approval";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { getAction } from "./tasks-static-data"
export default function Pickupappointment(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage === 1) {
        return <Customer />
    }
    if (stage >= 2) {
        return <Agraga />
    }
}
export function PickupappointmentTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [disableButton, setDisableButton] = useState(true)
    let task = taskStore.current
    let stage = task.stage
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            if (taskStore.current.dueDate.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
            }
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <TaskDatetime />
            <hr />
            <p>
                <button className="button islink"
                    disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        setDisableButton(true)
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}
function Customer(props) {
    useSnapshot(taskStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    let prefix = 'Pick-up '
    const [disableButton, setDisableButton] = useState(true)
    let actName = taskStore.current.actionName
    let sh = task.shipmentType
    let cn = task.containersList
    let con1 = (sh.toLowerCase().indexOf('fcl') != -1 && cn && cn.length > 0)?true:false
    let con2 = (actName == "Delivery Appointment" && sh.toLowerCase().indexOf("/factory") != -1)?true:false
    let con3 = (actName == "Pick-up Appointment" && sh.toLowerCase().indexOf("factory/") != -1)?true:false
    function check() {
        let disable = false
        if (con1 && (con2 || con3)) {
            let array = task.containersList
            for (let i = 0; i < array.length; i++) {
                if (array[i].pickupDate.length == 0 || array[i].pickupTimeFrom.length == 0 || array[i].pickupTimeTo.length == 0) {
                    disable = true
                    i = array.length+1
                }
            }
            if(!disable){
                for (let i = 0; i < task.details.length; i++) {
                    let e = task.details[i]
                    if(e['address'].length == 0 || e['pinCode'].length == 0){
                        disable = true
                        i = task.details.length+1
                    }
                    for (let j = 0; j < e['contactdetails'].length; j++) {
                        let k = e['contactdetails'][j]
                        if(k['contactName'].length == 0 || k['contactNumber'].length == 0 || k['contactEmail'].length == 0){
                            disable = true
                            j = e['contactdetails'].length+1
                            i = task.details.length+1
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < task.details.length; i++) {
                let e = task.details[i]
                if(e['address'].length == 0 || e['pinCode'].length == 0 || e['pickupDate'].length == 0 || e['pickupTimeFrom'].length == 0 || e['pickupTimeTo'].length == 0){
                    disable = true
                    i = task.details.length+1
                }
                for (let j = 0; j < e['contactdetails'].length; j++) {
                    let k = e['contactdetails'][j]
                    if(k['contactName'].length == 0 || k['contactNumber'].length == 0 || k['contactEmail'].length == 0){
                        disable = true
                        j = e['contactdetails'].length+1
                        i = task.details.length+1
                    }
                }
            }
        }

        setDisableButton(disable)
    }
    const addcontact = ()=>{
        return {
            contactName: '',
            contactNumber: '',
            contactEmail: ''
        }
    }
    const deletecontact = (k,j) =>{
        let l = []
        for (let z = 0; z < k.contactdetails.length; z++) {
            if(j!=z){
                l.push(k.contactdetails[z])
            }
        }
        return l
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    if (task.actionName.toLowerCase().startsWith('delivery')) prefix = 'Delivery '

    return (
        <>
            {
                (con1 && (con2 || con3)) ?
                    <div className="box">
                        {
                            task.details && task.details.length>0?<>
                            {
                                task.details.map((k,i)=>{
                                    return <>
                                    {
                                        (task.details.length>1)?<>
                                        <hr />
                                        <p><strong>{prefix +(i+1)}</strong></p>
                                        </>:""
                                    }
                                    <table>
                                        <tbody>
                                        <tr>
                            <td>{prefix} address:</td>
                            <td><input value={k.address} onChange={(e) => {
                                k.address = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Pin Code:</td>
                            <td><input value={k.pinCode} onChange={(e) => {
                                k.pinCode = e.target.value
                            }} /></td>
                        </tr>
                                        </tbody>
                                    </table>
                                    <hr />
                        {
                            (k.contactdetails && k.contactdetails.length>0)?<>
                            <table>
                                <tbody>
                                <tr>
                            <td>Contact Name</td>
                            <td>Contact Number</td>
                            <td>Contact Email</td>
                            <td></td>
                            </tr>
                            {
                                k.contactdetails.map((k1,j)=>{
                                    return <>
                                    <tr>
                            
                            <td><input value={k1.contactName} onChange={(e) => {
                                k1.contactName = e.target.value
                            }} /></td>
                            <td><input value={k1.contactNumber} onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                            }
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                                }} onChange={(e) => {
                                    k1.contactNumber = e.target.value
                                }} /></td>
                                <td><input value={k1.contactEmail} onChange={(e) => {
                                k1.contactEmail = e.target.value
                            }} /></td>
                            <td>
                                {
                                    (k.contactdetails.length>1)?<span className="icon" style={{color:"#f14668",cursor:"pointer"}} onClick={() => {
                                        k.contactdetails = deletecontact(k,j)
                                    }}>
                                        <i className="fas fa-trash"></i>
                                    </span>:""
                                }
                            {
                               (j==k.contactdetails.length-1)?<span className="icon" style={{color:"#48c78e",cursor:"pointer"}} onClick={() => {
                                k.contactdetails.push(addcontact())
                            }}>
                                <i className="fas fa-plus"></i>
                            </span>:""
                            }
                            

                            </td>
                        </tr>
                                    </>
                                })
                                
                            }
                            </tbody>
                            </table>
                            </>:<></>
                        }
                                    </>
                                })
                            }
                            </>:<></>
                        }
                        {
                            task.containersList.map((li, i) => {
                                return <>
                                    {(i == 0) ? <>
                                        <hr />
                                        <p><strong>Please provide the details for the {prefix} {li.container}</strong></p>
                                        <hr />

                                        <tr>
                                            <td>{prefix} date:</td>
                                            <td><input type='date' value={li.pickupDate} onChange={(e) => {
                                                li.pickupDate = e.target.value
                                                for (let k = 1; k < task.containersList.length; k++) {
                                                    task.containersList[k].pickupDate = e.target.value

                                                }
                                            }} /></td>
                                        </tr>
                                        <tr>
                                            <td>{prefix} Time (from):</td>
                                            <td>
                                                <select style={{ width: '3rem' }} onChange={(e) => {
                                                    li.pickupTimeFrom = e.target.value
                                                    for (let k = 1; k < task.containersList.length; k++) {
                                                        task.containersList[k].pickupTimeFrom = e.target.value

                                                    }
                                                }}>
                                                    {
                                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                                    }
                                                </select> hrs
                                            </td>
                                            <td>{prefix} Time (to):</td>
                                            <td>
                                                <select style={{ width: '3rem' }} onChange={(e) => {
                                                    li.pickupTimeTo = e.target.value
                                                    for (let k = 1; k < task.containersList.length; k++) {
                                                        task.containersList[k].pickupTimeTo = e.target.value

                                                    }
                                                }}>
                                                    {
                                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                                    }
                                                </select> hrs
                                            </td>
                                        </tr>
                                    </> : <>
                                        <hr />
                                        <p><strong>Please provide the details for the {prefix} {li.container}</strong></p>
                                        <hr />

                                        <tr>
                                            <td>{prefix} date:</td>
                                            <td><input type='date' value={li.pickupDate} onChange={(e) => {
                                                li.pickupDate = e.target.value
                                            }} /></td>
                                        </tr>
                                        <tr>
                                            <td>{prefix} Time (from):</td>
                                            <td>
                                                <select style={{ width: '3rem' }} value={li.pickupTimeFrom} onChange={(e) => {
                                                    li.pickupTimeFrom = e.target.value
                                                }}>
                                                    {
                                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                                    }
                                                </select> hrs
                                            </td>
                                            <td>{prefix} Time (to):</td>
                                            <td>
                                                <select style={{ width: '3rem' }} value={li.pickupTimeTo} onChange={(e) => {
                                                    li.pickupTimeTo = e.target.value
                                                }}>
                                                    {
                                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                                    }
                                                </select> hrs
                                            </td>
                                        </tr>

                                    </>}

                                </>
                            })
                        }

                        <hr />
                        <button className="button islink"
                            disabled={disableButton}
                            onClick={(e) => {
                                taskStore.current.stage = 2
                                taskStore.current.pendingWith = 'AGRAGA'
                                taskStore.setCustomerComplete()
                                Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }}>Submit</button>
                    </div> : <div className="box">
                        <p><strong>Please provide the details for the {prefix}</strong></p>
                        
                        {
                            task.details && task.details.length>0?<>
                            {
                                task.details.map((k,i)=>{
                                    return <>
                                    {
                                        (task.details.length>1)?<>
                                        <hr />
                                        <p><strong>{prefix +(i+1)}</strong></p>
                                        </>:""
                                    }
                                    <table>
                                        <tbody>
                                        <tr>
                            <td>{prefix} address:</td>
                            <td><input value={k.address} onChange={(e) => {
                                k.address = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Pin Code:</td>
                            <td><input value={k.pinCode} onChange={(e) => {
                                k.pinCode = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>{prefix} date:</td>
                            <td><input type='date' value={k.pickupDate} onChange={(e) => {
                                k.pickupDate = e.target.value
                            }} /></td>
                        </tr>
                        <tr>
                            <td>{prefix} Time (from):</td>
                            <td>
                                <select style={{ width: '3rem' }} onChange={(e) => {
                                    k.pickupTimeFrom = e.target.value
                                }}>
                                    {
                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                    }
                                </select> hrs
                            </td>
                            <td>{prefix} Time (to):</td>
                            <td>
                                <select style={{ width: '3rem' }} onChange={(e) => {
                                    k.pickupTimeTo = e.target.value
                                }}>
                                    {
                                        ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                            '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                                    }
                                </select> hrs
                            </td>
                        </tr>
                                        </tbody>
                                    </table>
                                    <hr />
                        {
                            (k.contactdetails && k.contactdetails.length>0)?<>
                            <table>
                                <tbody>
                                <tr>
                            <td>Contact Name</td>
                            <td>Contact Number</td>
                            <td>Contact Email</td>
                            <td></td>
                            </tr>
                            {
                                k.contactdetails.map((k1,j)=>{
                                    return <>
                                    <tr>
                            
                            <td><input value={k1.contactName} onChange={(e) => {
                                k1.contactName = e.target.value
                            }} /></td>
                            <td><input value={k1.contactNumber} onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                            }
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                                }} onChange={(e) => {
                                    k1.contactNumber = e.target.value
                                }} /></td>
                                <td><input value={k1.contactEmail} onChange={(e) => {
                                k1.contactEmail = e.target.value
                            }} /></td>
                            <td>
                                {
                                    (k.contactdetails.length>1)?<span className="icon" style={{color:"#f14668",cursor:"pointer"}} onClick={() => {
                                        k.contactdetails = deletecontact(k,j)
                                    }}>
                                        <i className="fas fa-trash"></i>
                                    </span>:""
                                }
                            {
                               (j==k.contactdetails.length-1)?<span className="icon" style={{color:"#48c78e",cursor:"pointer"}} onClick={() => {
                                k.contactdetails.push(addcontact())
                            }}>
                                <i className="fas fa-plus"></i>
                            </span>:""
                            }
                            

                            </td>
                        </tr>
                                    </>
                                })
                                
                            }
                            </tbody>
                            </table>
                            </>:<></>
                        }
                                    </>
                                })
                            }
                            </>:<></>
                        }
                        
                        
                        <button className="button islink" disabled={disableButton} onClick={(e) => {
                            taskStore.current.stage = 2
                            taskStore.current.pendingWith = 'AGRAGA'
                            taskStore.setCustomerComplete()
                            Taskapi.updateTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                            if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                AgmodalStore.pageVisible = false
                                AgmodalStore.apiCall = true
                            }
                        }}>Submit</button>
                    </div>
            }
        </>

    )
}
function Agraga(props) {
    useSnapshot(taskStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    let prefix = 'Pick-up '
    if (task.actionName.toLowerCase().startsWith('delivery')) prefix = 'Delivery '
    let actName = taskStore.current.actionName
    let sh = task.shipmentType
    let cn = task.containersList
    let con1 = (sh.toLowerCase().indexOf('fcl') != -1 && cn && cn.length > 0)?true:false
    let con2 = (actName == "Delivery Appointment" && sh.toLowerCase().indexOf("/factory") != -1)?true:false
    let con3 = (actName == "Pick-up Appointment" && sh.toLowerCase().indexOf("factory/") != -1)?true:false
    return (
        <>
            {
                ((con1 && (con2 || con3)) ? <>
                    {
                            task.details && task.details.length>0?<>
                            {
                                task.details.map((k,i)=>{
                                    return <>
                                    {
                                        (task.details.length>1)?<>
                                        <hr />
                                        <p><strong>{prefix +(i+1)}</strong></p>
                                        </>:""
                                    }
                                    <table>
                                        <tbody>
                                        <tr>
                            <td>{prefix} address:</td>
                            <td>{k.address}</td>
                        </tr>
                        <tr>
                            <td>Pin Code:</td>
                            <td>{k.pinCode}</td>
                        </tr>
                                        </tbody>
                                    </table>
                                    <hr />
                        {
                            (k.contactdetails && k.contactdetails.length>0)?<>
                            <table className="table">
                                <tbody>
                                <tr>
                            <td>Contact Name</td>
                            <td>Contact Number</td>
                            <td>Contact Email</td>
                            </tr>
                            {
                                k.contactdetails.map((k1,j)=>{
                                    return <>
                                    <tr>
                            
                            <td>{k1.contactName}</td>
                            <td>{k1.contactNumber}</td>
                                <td>{k1.contactEmail}</td>
                        </tr>
                                    </>
                                })
                                
                            }
                            </tbody>
                            </table>
                            </>:<></>
                        }
                                    </>
                                })
                            }
                            </>:<></>
                        }
                    <hr />
                    {
                        task.containersList.map((li) => {
                            return <>
                                <div className="box">
                                    <p><strong>Details for the {prefix}{li.container}</strong></p>
                                    <tr>
                                        <td>{prefix} date:</td>
                                        <td>{li.pickupDate}</td>
                                    </tr>
                                    <tr>
                                        <td>{prefix} Time (from):</td>
                                        <td>{li.pickupTimeFrom}</td>
                                        <td>{prefix} Time (to):</td>
                                        <td>{li.pickupTimeTo}</td>
                                    </tr>
                                </div>
                            </>
                        })
                    }
                    {
                        (taskStore.current.stage === 2) ?
                            <button className="button islink" onClick={(e) => {
                                taskStore.setComplete()
                                taskStore.current.stage = 3
                                Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }}>Acknowledge</button>
                            : <></>
                    }
                </> : <>
                    <div className="box">
                        <p><strong>Details for the {prefix}</strong></p>
                        {
                            task.details && task.details.length>0?<>
                            {
                                task.details.map((k,i)=>{
                                    return <>
                                    {
                                        (task.details.length>1)?<>
                                        <hr />
                                        <p><strong>{prefix +(i+1)}</strong></p>
                                        </>:""
                                    }
                                    <table>
                                        <tbody>
                                        <tr>
                            <td>{prefix} address:</td>
                            <td>{k.address}</td>
                        </tr>
                        <tr>
                            <td>Pin Code:</td>
                            <td>{k.pinCode}</td>
                        </tr>
                        <tr>
                            <td>{prefix} date:</td>
                            <td>{k.pickupDate}</td>
                        </tr>
                        <tr>
                            <td>{prefix} Time (from):</td>
                            <td>
                                {k.pickupTimeFrom}
                            </td>
                            <td>{prefix} Time (to):</td>
                            <td>
                                {k.pickupTimeTo}
                            </td>
                        </tr>
                                        </tbody>
                                    </table>
                                    <hr />
                        {
                            (k.contactdetails && k.contactdetails.length>0)?<>
                            <table className="table">
                                <tbody>
                                <tr>
                            <td>Contact Name</td>
                            <td>Contact Number</td>
                            <td>Contact Email</td>
                            </tr>
                            {
                                k.contactdetails.map((k1,j)=>{
                                    return <>
                                    <tr>
                            
                            <td>{k1.contactName}</td>
                            <td>{k1.contactNumber}</td>
                                <td>{k1.contactEmail}</td>
                        </tr>
                                    </>
                                })
                                
                            }
                            </tbody>
                            </table>
                            </>:<></>
                        }
                                    </>
                                })
                            }
                            </>:<></>
                        }
                        <hr />
                        {
                            (taskStore.current.stage === 2) ?
                                <button className="button islink" onClick={(e) => {
                                    taskStore.setComplete()
                                    taskStore.current.stage = 3
                                    Taskapi.updateTask(taskStore.current)
                                    taskStore.triggerActionVisible = false
                                    taskStore.myActionVisible = false
                                    if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                        AgmodalStore.pageVisible = false
                                        AgmodalStore.apiCall = true
                                    }
                                }}>Acknowledge</button>
                                : <></>
                        }
                    </div>
                </>)
            }
        </>

    )
}