import { useEffect, useState } from "react";
import {subscribe, useSnapshot } from "valtio";
import { AgSelect } from "../components/AgComponents";
import { Agyesno } from "../components/Agyesno";
import { AgmodalStore, bookingsStore, configStore, loginStore } from "../state/CommonState";
import Igmmanifesttable from "./igm-manifest-table";
import TaskDatetime from "./task-datetime";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { myactionsConstructUploads } from "./tasks-static-data";

export default function DOreleaseair(props) {
    const [linkedAction, setLinkedAction] = useState([])
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    useEffect(() => {
        let f = async () => {
            let b = bookingsStore.current
            let ret = await Taskapi.getTasklist4Booking(b._id)
            ret = ret.filter(e => e.actionName === 'AWB Upload')
            setLinkedAction(ret)
        }
        f()
    }, [bookingsStore.current._id])
    
    return (
        <>
        <strong>Your Delivery Order has been released</strong>
        <br></br>
        {
            (linkedAction.length>0)?<>
            {
                (task.isBROOriginal === 'Yes' && linkedAction[0].isBank === 'Yes') ?
                    <strong>The Consignee is a bank.<br></br>
                        Bank Release Order has been Received in Originals</strong>
                    
                    : <></>
            }

{
                (task.isBROOriginal === 'No' && linkedAction[0].isBank === 'Yes') ?
                <strong>The Consignee is a bank</strong>
                    
                    : <></>
            }
            </>:<></>
        }
            
            
            
            <Taskfiledisplay />

            {
                (task.stage === 1) ?
                    <p>
                        <button className="button islink"
                            onClick={async (e) => {
                                taskStore.current.stage = 2
                                taskStore.current.pendingWith = 'AGRAGA'
                                taskStore.setComplete()
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }}>Acknowledge</button>
                    </p>
                    : <></>
            }
        </>
    )
}
export function DOreleaseairTrigger(props) {
    const [linkedAction, setLinkedAction] = useState([])
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    const [disableButton,setDisableButton] = useState(true)
    
    function check(){
        let disable = false
        let checkfile = []
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        setDisableButton(disable)
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    // useEffect(() => {
    //     let f = async () => {
    //         let b = bookingsStore.current
    //         let ret = await Taskapi.getTasklist4Booking(b._id)
    //         ret = ret.filter(e => e.actionName === 'BL Upload')
    //         setLinkedAction(ret)
    //     }
    //     f()
    // }, [bookingsStore.current._id])
    useEffect(() => {
        let f = async () => {
            let b = bookingsStore.current
            let ret = await Taskapi.getTasklist4Booking(b._id)
            ret = ret.filter(e => e.actionName === 'AWB Upload')
            setLinkedAction(ret)
        }
        f()
    }, [bookingsStore.current._id])
    useEffect(() => {
        if (linkedAction.length > 0) {
            let task1 = linkedAction[0]
            if (task1.isBank === 'Yes') {
                if (taskStore.current.isBROOriginal === 'Yes')
                    {
                        task.files = myactionsConstructUploads(['Scan copy of the BRO OR Bank Endorsed Copy of the AWB', 'Browse & Upload the Screenshot'])
                        task.original_file = ['Scan copy of the BRO OR Bank Endorsed Copy of the AWB']
                    }
                else
                    {
                        task.files = myactionsConstructUploads(['Browse & Upload the Screenshot'])
                        task.original_file = []

                    }
            }
            if (task1.isBank === 'No') {
                {
                    task.files = myactionsConstructUploads(['Browse & Upload the Screenshot'])
                    task.original_file = []

                }
            }
        }
    }, [taskStore.current.isBROOriginal, taskStore.current.isOriginalEndorsedBLReceived])
    if (linkedAction.length === 0) return <>AWB Upload for action not completed yet</>
    let task1 = linkedAction[0]
    return (
        <>
            {/* <TaskDatetime /> */}
            <hr />
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                {
                    (task1.isBank === 'Yes') ?
                        <tr>
                            <td>
                                The Consignee is a Bank. Please confirm if the Bank Release Order has been Received in Originals?
                            </td>
                            <td>
                                <Agyesno label='No/Yes' record={taskStore.current} name='isBROOriginal' />
                            </td>
                            <td>
                                <div style={{ width: '20rem' }}></div>
                            </td>
                        </tr>
                        : <></>
                }
            </table>
            <Taskfiletable />

            <p>
                <button className="button islink"
                disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        setDisableButton(true)
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}